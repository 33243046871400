import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { InvoiceInfoDto } from '@sr/dto';
import { useSupplierInvoices } from 'entities/client-invoices-list/lib/supplier-invoices-list.hook';
import { FormData, formTools } from 'features/invoice/create-supplier-invoice/supplier-invoice.form-tools';
import { SupplierInvoicesList } from 'entities/invoice/list';
import { useState } from 'react';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { useSnack } from 'shared/ui/snack';
import { AddSupplierInvoiceDialog } from 'features/invoice/create-supplier-invoice/add-supplier-invoice-dialog';
import { useOurCompany } from 'hooks/portal-settings.hook';
import { DEFAULT_PAGINATION } from 'shared/ui/grid-layout';
import { useAddInvoice } from 'entities/invoice/invoice.hook';
import { useSearchParams } from 'react-router-dom';

type Props = {
  companyId: number;
};

export const CompanySupplierInvoices = (props: Props) => {
  const { companyId } = props;
  const [paging, setPaging] = useState(DEFAULT_PAGINATION);
  const [searchParams] = useSearchParams();

  const { collection, changeCancelStatus } = useSupplierInvoices(paging, companyId, searchParams.toString());
  const addInvoice = useAddInvoice({ companyId });
  const ourCompanyId = useOurCompany();

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const { showError, showSuccess } = useSnack();
  const showConfirm = useConfirmDialog();

  const handleAddInvoice = async (data: FormData) => {
    const dto = formTools.toCreateDto(data);

    dto.buyerCompanyId = ourCompanyId;
    dto.sellerCompanyId = companyId;
    addInvoice(dto)
      .then(() => {
        showSuccess('Счет успешно добавлен');
        setAddDialogOpen(false);
      })
      .catch(e => showError(`Ошибка добавления счета: ${e.data.message}`));
  };

  const handleCancelInvoice = async (invoice: InvoiceInfoDto) =>
    showConfirm({ content: 'Отменить счет?' })
      .then(() => changeCancelStatus(invoice.id))
      .then(({ isCanceled }) => showSuccess(`Счет ${isCanceled ? 'отменён' : 'восстановлен'}`))
      .catch(e => showError(`Ошибка отмены счета: ${e.message}`));

  return (
    <>
      <Button
        sx={{ mb: 2 }}
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setAddDialogOpen(true)} >
        Внести счет
      </Button>
      <SupplierInvoicesList
        collection={collection}
        paging={paging}
        onChangePaging={setPaging}
        onCancel={handleCancelInvoice} />
      <AddSupplierInvoiceDialog
        open={addDialogOpen}
        supplierCompanyId={companyId}
        ourCompanyId={ourCompanyId}
        onClose={() => setAddDialogOpen(false)}
        onSubmit={handleAddInvoice}
        isCompanyPageMode={true} />
    </>);
};
