import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { EmailAccountLogParserDto, emptyPagedCollection, IPagingData } from '@sr/dto';
import { emailParserApi } from './email-parser.api';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';

export const useEmailParserAccountList = (pagination: IPagingData, filter?: URLSearchParams): IRemotePagedCollection<EmailAccountLogParserDto> => {
  const { data, isLoading, error } = emailParserApi.useGetEmailParserAccounsQuery(
    { paging: pagination, filter: filter?.toString() || '' },
    { refetchOnMountOrArgChange: 60 }
  );

  return {
    collection: data || emptyPagedCollection,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading,
  };
};

export const useParseLog = (id: number) => {
    const { data, isLoading, error } = emailParserApi.useGetParseLogQuery(id);
    return {
      collection: data || emptyPagedCollection,
      error: extractNestJsErrorMessage(error),
      isLoading: isLoading
    };
  };