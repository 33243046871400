import { Box, Button, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { EmailAccountLogParserDto, Interval, SupportedProviders, UserDto } from '@sr/dto';
import { useState } from 'react'
import { FormDialog } from 'shared/ui/form-dialog';
import { GridLayout } from 'shared/ui/grid-layout';
import AddIcon from '@mui/icons-material/Add';
import { FormikErrors, FormikValues } from 'formik';
import { VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { useSnack } from 'shared/ui/snack';
import { usePagingParams } from 'shared/hooks/paging-params';
import { useEmailParserAccountList } from 'email-parser/email-parser.hooks';
import { useSearchParams } from 'react-router-dom';
import { EmailAccountParserFilter } from 'features/email-parser/email-parser-filter';
import { emailParserApi } from 'email-parser/email-parser.api';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { LinkToEmailAccount } from 'shared/ui/links/link-to-email-account';
import { useStateSelector } from 'shared/store';
import EmailAccountForm from 'email-parser/email-account.form';

const columns = [
    'Email',
    'Пользователь',
    'Тип',
    'Кол-во писем',
    'Статус проверки',
    'Активность'
  ];
  
export interface FormValues {
  email: string,
  user: UserDto | null,
  login: string,
  password: string,
  provider: Interval | '',
  checkInterval: SupportedProviders | '',
  userId: number | null,
  active: boolean,
  general: boolean,
}

export const validate = (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {};
  if (!values.login) errors.login = VALIDATION_REQUIRED_FIELD;
  if (!values.password) errors.password = VALIDATION_REQUIRED_FIELD;
  if (!values.provider) errors.provider = VALIDATION_REQUIRED_FIELD;
  if (!values.checkInterval) errors.checkInterval = VALIDATION_REQUIRED_FIELD;
  if (!values.userId) errors.userId = VALIDATION_REQUIRED_FIELD;
  if (!values.email) errors.email = VALIDATION_REQUIRED_FIELD;

  return errors;
}

const initialValues: FormValues = {
  email: '',
  login: '',
  user: null,
  password: '',
  provider: '',
  checkInterval: '',
  userId: null,
  active: false,
  general: false
}

export const EmailParserList = () => {
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [ paging, setPaging ] = usePagingParams();
  const [searchParams] = useSearchParams();

  const { collection, isLoading, error } = useEmailParserAccountList(paging, searchParams);

  const { showSuccess, showError } = useSnack();
  const [addItem,{isError}] = emailParserApi.useCreateEmailAccountTypeMutation()
    
  const handleAddEmailAccount = async (values: FormikValues) => {    
      await addItem(({
          email: values.email,
          login: values.login,
          password: values.password,
          provider: values.provider,
          checkInterval: values.checkInterval,
          userId: values.userId,
          active: values.active,
          general: values.general
        }))      
        .unwrap()
        .then(() => 
          {showSuccess('Запись успешно добавлена')
          setShowAddDialog(false)}
        )
        .catch(e => showError(`Ошибка записи: ${extractNestJsErrorMessage(e)}`))
    };
  return (
    <>
    <Box>
        <Button variant="contained" startIcon={<AddIcon />}
          onClick={() => setShowAddDialog(true)}>Добавить
        </Button>
    </Box>

      <Box mb={1}>
        <EmailAccountParserFilter/>
      </Box>

       <GridLayout<EmailAccountLogParserDto>
        columns={columns}
        items={collection.items}
        isLoading={isLoading}
        error={error}
        pagingData={paging}
        onChangePaging={setPaging}
        totalItemsCount={collection.totalItemsCount}
        noItemsText='Почта не существует'
        itemRender={(item) => <EmailAccountRow
          key={item.id}
          emailAccount={item}   
          />}
      />

  <FormDialog
      initialValues={initialValues} 
        renderForm={() =>
        <Box minWidth={450} pb={1}>
          <Stack spacing={2} direction="column">
            <EmailAccountForm />
          </Stack>
        </Box>
        }
        renderTitle="Новая почта"
        open={showAddDialog}
        onClose={() => setShowAddDialog(false)}
        onSubmit={handleAddEmailAccount}
        validate={validate}
        dialogProps={{
          fullWidth: true,
        }}
      />
      </>
    );
  };

type RowProps = {
  emailAccount: EmailAccountLogParserDto;
};

export const EmailAccountRow = ({ emailAccount }: RowProps) => {
  return (
    <>
     <TableRow
        key={emailAccount.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <LinkToEmailAccount emailAccount={emailAccount} />
        </TableCell>
        <TableCell component="th" scope="row">
          {emailAccount.user.firstName} {emailAccount.user.secondName}
        </TableCell>
        <TableCell>{emailAccount.general?'Общий':'Личный'}</TableCell>
        <TableCell>{
          (Object.keys(JSON.parse(emailAccount.logs[0].report)).length===0 || emailAccount.logs[0].status=='ERROR') ? 
          "0" :
          JSON.parse(emailAccount.logs[0].report).INBOX.messages
          } шт.
          </TableCell>
        <TableCell>{emailAccount.logs[0].status == 'SUCCESS' ?
        <Typography color="green">SUCCESS</Typography> : 
        <Typography color="error">ERROR</Typography> 
        }</TableCell>
        <TableCell>
          {emailAccount.active ? 
            <Typography color="green">Активирован</Typography> : 
            <Typography color="error">Деактивирован</Typography>
          }</TableCell>
      </TableRow>
    </>
  );
};

export default EmailParserList;