import { useMemo } from 'react';
import { INVOICE_TYPE_CLIENT, InvoiceDto, PurchaseDto } from '@sr/dto';
import { useInvoicePurchases } from 'hooks/purchases-list.hook';
import { useInvoiceDetails } from 'entities/invoice/invoice.hook';
import { IRemoteCollection } from 'utils/remote-collection.hook';

export const useRelatedPurchases = (invoiceId: number) => {
  const { invoice } = useInvoiceDetails(invoiceId);
  const purchases = useInvoicePurchases(invoiceId);

  const purchasesProfit = useMemo(
    () =>
      purchases.items.reduce(
        (prev, curr) => prev + calcPurchaseProfit(curr),
        0
      ),
    [purchases]
  );

  const relatedInvoices = useMemo<IRemoteCollection<InvoiceDto>>(() => {
    if (!invoice)
      return {
        items: [],
        isLoading: true,
        error: '',
        reload: () => Promise.resolve(),
      };

    const allRelatedInvoices = purchases.items
      .filter((p) =>
        invoice.type === INVOICE_TYPE_CLIENT
          ? !!p.supplierInvoiceRow
          : !!p.clientInvoiceRow
      )
      .map((purchase) =>
        invoice.type === INVOICE_TYPE_CLIENT
          ? purchase.supplierInvoice
          : purchase.clientInvoice
      );
    const uniqueIds = [...new Set(allRelatedInvoices.map((i) => i.id))];
    const relatedInvoices = uniqueIds.map((id) =>
      allRelatedInvoices.find((i) => i.id === id)
    ) as InvoiceDto[];
    return {
      items: relatedInvoices,
      isLoading: false,
      error: '',
      reload: () => Promise.resolve,
    };
  }, [purchases, invoice]);

  const relatedTotalCost = useMemo(
    () =>
      relatedInvoices.items.reduce(
        (acc, current) => acc + current.totals.discountPriceTotal,
        0
      ),
    [relatedInvoices]
  );

  const relatedPaid = useMemo(
    () =>
      relatedInvoices.items.reduce(
        (acc, current) => acc + current.totals.partialPaidAmount,
        0
      ),
    [relatedInvoices]
  );

  return {
    purchases,
    purchasesProfit,
    relatedInvoices: relatedInvoices,
    totalInvoicesCost: relatedTotalCost,
    totalInvoicePaied: relatedPaid,
  };
};

export const calcPurchaseProfit = (purchase: PurchaseDto) => {
  return purchase.supplierInvoiceRow
    ? purchase.clientInvoiceRow.priceDiscount -
        purchase.supplierInvoiceRow.priceDiscount
    : 0;
};
