import React, { SyntheticEvent } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

type Props = {
  audioUrl: string;
}

const AudioPlayer = ({ audioUrl }: Props) => {
  const [error, setError] = React.useState<string>('');

  const handleError = (error: SyntheticEvent) => {
    console.error(`Ошибка воспроизведения аудио: ${audioUrl}: `, error);
    setError('Аудио нельзя проиграть');
  };

  if (error) return <Box display="flex" alignItems="center" px={3}>
    <Typography color="orange">Аудио не доступно к проигрыванию</Typography>
  </Box>;

  return (
    <Box display="flex" alignItems="center" flexDirection="column" p={2}>
      <audio controls src={audioUrl} onError={handleError}></audio>
    </Box>
  );
};

export default AudioPlayer;
