import { Button, Stack, Typography } from "@mui/material";
import { Interval, SupportedProviders } from "@sr/dto";
import EmailAccountForm from "email-parser/email-account.form";
import { emailParserApi } from "email-parser/email-parser.api";
import LogParser from "email-parser/log-parser";
import { Form, Formik, FormikValues } from "formik";
import { useTitleHook } from "hooks/page-title.hook";
import { extractNestJsErrorMessage } from "shared/api/rtk-query";
import { PageHeader } from "shared/ui/details-page-header";
import { LoadingBanner } from "shared/ui/loading-banner";
import { PageBreadCrumbs } from "shared/ui/page-breadcrumbs";
import { useSnack } from "shared/ui/snack";
import { useIdParam } from "utils/url-paramters.hook";

export interface FormData {
  email: string,
  login: string,
  password: string,
  provider: SupportedProviders | '',
  checkInterval: Interval | '',
  userId: number | '',
  active: boolean,
  general: boolean,
}

export const useEmailAccount = (id: number) => {
  const { data, isLoading, isFetching, error } = emailParserApi.useGetEmailAccountQuery(id);
  return {
    data: data, 
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading || isFetching
  };
};

const EmailAccountPage = () => {
  const links = [
    { text: 'Настройки', to: '/settings/home' },
    { text: 'Почта', to: '/settings/email-parser' }
  ]; 

  const emailAccountId = useIdParam();  

  const { data, isLoading, error } = useEmailAccount(emailAccountId);

  useTitleHook('Настройка почты');

  const initialValues = {
    email: String(data?.email),
    login: String(data?.login),
    password: String(data?.password),
    provider: data?.provider || SupportedProviders.YANDEX,
    checkInterval: data?.checkInterval || Interval.ONE_DAY,
    userId: Number(data?.userId),
    active: Boolean(data?.active),
    general: Boolean(data?.general)
  }

  const [updateItem,{isError}] = emailParserApi.useUpdateEmailAccountMutation()
  const { showSuccess, showError } = useSnack();
   
  const handleSubmit =  async (values: FormikValues) => {
  await updateItem(({
    id: emailAccountId, 
    email: values.email,
    login: values.login,
    password: values.password,
    provider: values.provider,
    checkInterval: values.checkInterval,
    userId: values.userId,
    active: values.active,
    general: values.general
  }))
      .unwrap()
      .then(() => 
        {
          showSuccess('Запись успешно обновлена')
        }
      )
      .catch(e => showError(`Ошибка записи: ${extractNestJsErrorMessage(e)}`))
  };

  if (isLoading) return <LoadingBanner />;

  return (
    <>
    <PageBreadCrumbs links={links}/>
    <PageHeader
          title={
            <Typography variant="h5" color="primary">{data?.email}</Typography>
          }
    />
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      <Form>
        <Stack spacing={2}>
          <EmailAccountForm />
          <Button variant="contained" sx={{ ml: 'auto', mr: 1 }} type="submit">Сохранить</Button>
        </Stack>
      </Form>
    </Formik>
    <br /><br />
    <LogParser id={emailAccountId}/>
    </>
  );

};

export default EmailAccountPage;