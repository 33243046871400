import { Typography } from '@mui/material';
import {  WccDto } from '@sr/dto';
import { LegendItem, LegendList } from 'shared/ui/legend-list';
import PriceSpan from 'shared/ui/price-span';
import { CardPaper } from 'shared/ui/card-paper';
import { formatDate } from 'utils/date-format-helpers';
import { WccButton } from './wcc-button';
import { WccStatusSpan } from './wcc-status-span';

export const WccCard = ({ wcc }: { wcc: WccDto }) => {
  return (
    <CardPaper>
      <Typography variant='h6'>Акт </Typography>
      <LegendList>
        <LegendItem title="Действия: " value={<WccButton initialWcc={wcc} invoiceId={wcc.invoice.id}/>} />
        <LegendItem title="Статус: " value={<WccStatusSpan status={wcc.status} />} />
        <LegendItem title="Сумма акта: " value={<PriceSpan size="small" price={wcc.amount} />} />
        <LegendItem title="Дата счета: " value={formatDate(wcc.date)} />
      </LegendList>
    </CardPaper>
  );
};
