import { Type } from "class-transformer";
import {
  IsDate,
    IsNumber,
    IsString,
} from "class-validator";

export class CreateParseLogDto {
    @IsNumber()
    accountId: number;

    @IsString()
    status: string;
  
    @IsString()
    report: string;

    @IsDate()
    @Type()
    createdAt: Date;
  }
  
  export class ParseLogDto extends CreateParseLogDto {
    @IsNumber()
    id: number;
  }