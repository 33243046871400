import { Button, Paper } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { ErrorBanner } from 'shared/ui/error-banner';
import { useSnack } from 'shared/ui/snack';
import {
  MANDATORY_COLUMNS,
  NULL_USER_ID,
  useTimeSheetData,
  useUpdateTimeSheetRow
} from './time-sheet.hook';
import './cell-style.css';
import { isWeekend } from 'date-fns';
import { useStateSelector } from 'shared/store';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';


export const TimeSheet = () => {
  const [isFullView, setFullView] = useState(true);
  const selectedPeriod = useStateSelector((state) => state.salaryPageSelectedPeriod.value);
  const { showError, showSuccess } = useSnack();
  const { rows, columns, isLoading, error } = useTimeSheetData(selectedPeriod.year, selectedPeriod.month);

  const getColsView = () => {
    if (isFullView) {
      return columns;
    }
    const mandatoryColumns = Object.values(MANDATORY_COLUMNS).map(col => col.FIELD);
    return columns.filter(col=> mandatoryColumns.includes(col.field));
  };

  useEffect(()=>{
    if (!rows.length) return;
    const isAllRowsClosed = rows
      .filter(row=>row.id !== NULL_USER_ID)
      .every(row=>row.isClosed);
    if (isAllRowsClosed) {
      setFullView(false);
    }
  }, [rows.length]);

  const updateRow = useUpdateTimeSheetRow();
  const handleCellEdit = async (updatedRow: any, originalRow: any)=>{
    const { id: userId, name, isClosed, ...payload } = updatedRow;
    await updateRow({
      year: selectedPeriod.year,
      month: selectedPeriod.month,
      userId: userId === NULL_USER_ID ? null: userId,
      payload: payload });
    return updatedRow;
  };

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    console.error(error);
    showError('Изменения не сохранены: ' + extractNestJsErrorMessage(error));
  }, []);

  if (isLoading){
    return <LoadingBanner />;
  }

  if (error) {
    return <ErrorBanner errorMessage={error} />;
  }
  const gridHeight = () => {
    // workaround on an empty line bug
    // https://stackoverflow.com/questions/76392015/mui-datagrid-filling-remaining-vertical-space-without-overflowing
    return String(6 + (rows.length + 2 ) * 52)+'px';
  };

  const getCellStyle = (params: GridCellParams<any, any, number>) => {
    let classNames = '';
    if (params.id === NULL_USER_ID) {
      classNames += 'working-hours-row ';
    }
    const date = new Date(selectedPeriod.year, selectedPeriod.month, Number(params.field));
    if (isWeekend(date)) {
      classNames += 'holiday';
    }
    return classNames;
  };

  const isCellEditable = (params: GridCellParams<any, any, number>): boolean => {
    return !params.row.isClosed;
  };

  return (
    <>
      <Paper sx={{ width: '100%' }}>
        <Button sx={{ p: 2 }} onClick={()=>{ setFullView((x) => !x) }}>
          { isFullView ? 'Краткий' : 'Подробный'}
        </Button>
        <div style={{ position: 'relative', width: '100%', height: gridHeight() }}>
          <DataGrid
            style={{ position: 'absolute', height: '100%', width: '100%' }}
            editMode="cell"
            rows={rows} columns={getColsView()}
            processRowUpdate={handleCellEdit}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            getCellClassName={getCellStyle}
            isCellEditable={isCellEditable}
          />
        </div>
      </Paper>
    </>
  );
};
