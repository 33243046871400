import { PayOrderGroup, POG_CLIENT_INVOICE_STATUSES, POG_EMPLOYEE_PAYMENT, POG_SUPPLIER_INVOICE_STATUSES } from "./payorder";

type CLIENT = 1;
type SUPPLIER = 2;

// исходящие клиентские счета
export const INVOICE_TYPE_CLIENT: CLIENT = 1;

// входящие счета от поставщиков
export const INVOICE_TYPE_SUPPLIER: SUPPLIER = 2;

export type InvoiceType = CLIENT | SUPPLIER;

// type InvTypeNames = 'client' | 'supplier'

// export const InvType: { [key in InvTypeNames]: InvoiceType } = {
//   client: INVOICE_TYPE_CLIENT,
//   supplier: INVOICE_TYPE_SUPPLIER
// }

export const getInvoiceTypeByPayOrderGroup = (group: PayOrderGroup) => {
    if(group === POG_EMPLOYEE_PAYMENT) return null;
    if((POG_CLIENT_INVOICE_STATUSES as number[]).includes(group)) return INVOICE_TYPE_CLIENT;
    if((POG_SUPPLIER_INVOICE_STATUSES as number[]).includes(group)) return INVOICE_TYPE_SUPPLIER;
    return null;
}
