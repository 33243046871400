import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, MenuItem, Stack } from '@mui/material';
import { Field, FormikProvider, useFormik } from 'formik';
import { CardPaper } from 'shared/ui/card-paper';
import { TextFieldClearable } from 'shared/ui/form-fields/text-field-clearable';
import { formValuesFromUrlSearchParams, mergeFormValuesVsCurrentSearchParams } from 'utils/url-search-params.helper';
import { PAGE_FIELD_NAME } from 'shared/hooks/paging-params';
import { TextField } from 'formik-mui';
import { UserPickerField } from 'features/user/user-picker';

type EmailParserFilter = {
  email: string;
  type: number | string;
  status: string;
  active: number | string;
  userId: number | null;
}

export const defaultFormValues: EmailParserFilter = {
  email: '',
  type: '',
  status: '',
  active: '',
  userId: null
};

const DEBOUNCE_DELAY = 500;

export const EmailAccountParserFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: formValuesFromUrlSearchParams(searchParams, defaultFormValues),
    onSubmit: () => {},
    validateOnChange: true,
    initialTouched: { email: true },
    validate: (values)=> {
      if (!!values.email && values.email.length > 0 && values.email.length <3) {
        return { email: 'Наберите хотя бы 3 символа для поиска' };
      }
    }
  });

  useEffect(() => {
    const setCurrentSearchParams = () => {
      setSearchParams(prev => {
        const params = mergeFormValuesVsCurrentSearchParams(prev, formik.values, defaultFormValues);
        if (formik.dirty) {
          // раз пользователь обновил фильтр, сбрасываем страницу на 0
          params.set(PAGE_FIELD_NAME, '0');
        }
        return params;
      });
    };

    const cancel = setTimeout(()=>{
      if (formik.isValid) {
        setCurrentSearchParams();
      }
    }, DEBOUNCE_DELAY);
    return () => clearTimeout(cancel);
  }, [formik.values, formik.isValid]);

  return (
    <CardPaper>
      <FormikProvider value={formik}>
      <Stack  spacing={1} direction="row">
          <Box sx={{minWidth: 200, flexGrow:1}}>
            <Field
              fullWidth
              type="text"
              name="email"
              label="email"
              helperText="Начните набирать для фильтрации"
              component={TextFieldClearable}
            />
          </Box>
          <Box sx={{minWidth: 200, flexGrow:1}}>
          <Field
              fullWidth
              clearable
              name="userId"
              label="Пользователь"
              component={UserPickerField}
            /> 
          </Box>
          <Box sx={{ minWidth: 200, flexGrow: 1 }}>
                 <Field
                    label='Тип'
                    fullWidth
                    component={TextField}
                    select
                    name='type'
                  >
                    <MenuItem
                      key=""
                      value=""
                    >
                    &nbsp;
                    </MenuItem>
                    <MenuItem key={0} value={"0"}>
                        Личный
                      </MenuItem>
                    <MenuItem key={1} value={"1"}>
                        Общий
                    </MenuItem>
                  </Field>
                </Box>

                <Box sx={{ minWidth: 200, flexGrow: 1 }}>
                <Field
                    label='Статус'
                    fullWidth
                    component={TextField}
                    select
                    name='status'
                  >
                    <MenuItem
                      key=""
                      value=""
                    >
                    &nbsp;
                    </MenuItem>
                    <MenuItem key={0} value={"ERROR"}>
                        ERROR
                      </MenuItem>
                    <MenuItem key={1} value={"SUCCESS"}>
                        SUCCESS
                    </MenuItem>
                  </Field>
                </Box>

                <Box sx={{ minWidth: 200, flexGrow: 1 }}>
                <Field
                    label='Активность'
                    fullWidth
                    component={TextField}
                    select
                    name='active'
                  >
                    <MenuItem
                      key=""
                      value=""
                    >
                    &nbsp;
                    </MenuItem>
                    <MenuItem key={0} value={"0"}>
                        Деактивирован
                      </MenuItem>
                    <MenuItem key={1} value={"1"}>
                        Активирован
                    </MenuItem>
                  </Field>
                </Box>
        </Stack>
      </FormikProvider>
    </CardPaper >
  );
};
