import { Type } from 'class-transformer';
import { PurchaseDto } from './purchase.dto';
import { PayOrderDto } from './payorder.dto';
import { InvoiceRowDto } from './invoice-row.dto';
import { InvoiceDto } from './invoice.dto';

export class InvoiceReportDto {
  rows: InvoiceReportRowDto[];
  clientPayments: PayOrderDto[];
}

export class InvoiceReportRowDto {
  @Type(() => InvoiceRowDto)
  buyerRow: InvoiceRowDto;

  @Type(() => PurchaseDto)
  purchase?: PurchaseDto;

  @Type(() => InvoiceRowDto)
  sellerRow?: InvoiceRowDto;

  @Type(() => InvoiceDto)
  sellerInvoice?: InvoiceDto;

  @Type(() => PurchaseDto)
  sellerPayments?: PayOrderDto[];

  profitByPurchases: number;
  profitByPayments?: number;
}
