import { Link as MaterialLink } from '@mui/material';
import { DownloadFileDto } from '@sr/dto';
import { fileLinkManager } from 'utils/link-manager';
import { ReactNode } from 'react';

export const fileNameCutter = (name: string, length = 20): string => {
  return name.length > length ? name.slice(0, length - 3) + '...' : name;
};

export const LinkToFile = ({ file: { id, fileName }, icon }: { file: DownloadFileDto, icon?: ReactNode }) => {
  const link = fileLinkManager.link(id);

  return (
    <MaterialLink href={link} target="_self" download={fileName}>
      {icon}
      {fileNameCutter(fileName)}
    </MaterialLink>
  );
};
