import { Box, Button, Card, CardActions, CardContent, MenuItem, Stack, Typography } from '@mui/material';
import { CAPPENDIX_STATUSES, CAPPENDIX_STATUSES_KEYS } from '@sr/dto';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { Select, TextField } from 'formik-mui';
import { formTools, FormValues } from './contract-appendix.form-tools';
import { ContractAppendixDto } from '@sr/dto/dist/src/contract-appendix.dto';
import FilePickerField from 'shared/ui/formik-fields/file-picker';
import { ContentProviderRef, RichEditor } from 'shared/ui/rich-editor/rich-editor';
import { useRef } from 'react';

type Props = {
    appenidx: ContractAppendixDto,
    update: (formData: FormValues) => Promise<void>;
};

export const ContractAppendixBasicsCard = ({ appenidx, update }: Props) => {
  const initialValues = formTools.fromDto(appenidx);
  const extraConditionsText = useRef<ContentProviderRef>(null);
  return (
    <Card>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={update}
        validate={formTools.validate}
      >
        {({ submitForm, isSubmitting, isValid, dirty }: FormikProps<FormValues>) => (
          <>
            <CardContent>
              <Stack direction="row" spacing={2}>
                <Box width="30%">
                  <Stack direction="column" spacing={2}>
                    <Field
                      name="date"
                      label="Дата приложения"
                      component={DatePickerField}
                    />

                    <Field
                      name="scanFile"
                      label="Файл скана"
                      component={FilePickerField}
                    />

                    <Field
                      name="status"
                      label="Статус"
                      component={Select}
                    >
                      {CAPPENDIX_STATUSES_KEYS.map(key =>
                        <MenuItem key={key} value={key}>
                          {CAPPENDIX_STATUSES[key]}
                        </MenuItem>
                      )}
                    </Field>
                  </Stack>
                </Box>
                <Box width="100%">
                <Typography sx={{ mb: 1 }}>Дополнительные условия</Typography>
                  <Field name='extraConditionsText'>
                    {({ field, form, meta }: FieldProps) => (
                      <RichEditor content={field.value} disabled={isSubmitting}
                        ref={extraConditionsText}
                        onChange={() => {form.setFieldValue('extraConditionsText', extraConditionsText.current?.())}}
                      />
                    )}
                  </Field> 
                  <Box><br/></Box>
                  <Field
                      name="promoFile"
                      label="Рекламные материалы"
                      pickerType="image"
                      maxFileSize={10}
                      component={FilePickerField}
                    />
                </Box>               
              </Stack>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                sx={{ ml: 'auto', mr: 1 }}
                onClick={submitForm}
                disabled={!dirty || isSubmitting || !isValid}
              >
                                Сохранить
              </Button>
            </CardActions>
          </>
        )}
      </Formik>
    </Card>
  );
};
