import { CreatePrepaymentDto, PayOrderStatus, POS_DRAFT, PrepaymentDto, UpdatePrepaymentDto } from '@sr/dto';
import { FormTools, VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';
import { FormikErrors } from 'formik';
import { NotificationsConfig } from '../../shared/ui/snack';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Period } from '../period-picker/types';
import { getCurrentPeriod } from '../period-picker/period.helpers';

export type FormData = {
  period: Period;
  sum: number;
  status: PayOrderStatus,
  description: string;
  userId: number | '';
  accountId: number | '';
  paymentDate: Date;
};

export const formTools: FormTools<
  PrepaymentDto,
  CreatePrepaymentDto,
  UpdatePrepaymentDto,
  FormData
> = {
  toUpdateDto: (id, formData) => {
    return ({
      id: id,
      sum: formData.sum,
      accountId: formData.accountId as number,
      userId: formData.userId as number,
      paymentDate: formData.paymentDate,
      status: formData.status,
      description: formData.description,
      month: formData.period.month,
      year: formData.period.year,
    });
  },

  toCreateDto: (formData) => {
    return ({
      sum: formData.sum,
      accountId: formData.accountId as number,
      userId: formData.userId as number,
      paymentDate: new Date(formData.paymentDate),
      status: formData.status,
      description: formData.description,
      month: formData.period.month,
      year: formData.period.year,
    });
  },

  fromDto: (dto) => {
    return ({
      sum: dto.sum,
      accountId: dto.accountId,
      userId: dto.userId,
      description: dto.description,
      status: dto.status,
      paymentDate: new Date(dto.paymentDate),
      period: { month: dto.month, year: dto.year },
    });
  }
  ,

  validate: (values): FormikErrors<FormData> => {
    const errors: FormikErrors<FormData> = {};

    if (!values.accountId) {
      errors.accountId = VALIDATION_REQUIRED_FIELD;
    }
    if (values.sum <= 0) {
      errors.sum = 'Должна быть положительной';
    }
    if (!values.userId) {
      errors.userId = VALIDATION_REQUIRED_FIELD;
    }
    return errors;
  },

  emptyValues: () => {
    return ({
      sum: 0,
      accountId: '',
      userId: '',
      description: `Аванс за ${format(new Date(), 'LLLL yyyy', { locale: ru })}`,
      status: POS_DRAFT,
      paymentDate: new Date(),
      period: getCurrentPeriod(),
    });
  },
};


export const notificationsConfig: NotificationsConfig = {
  itemTitleForm1: 'аванс',
  itemTitleForm2: 'аванса',
  gender: 'neutral',
};
