import { useState } from 'react';
import { useInvoicesWithoutWcc } from 'features/wcc-bulk-create/invoices-without-wcc.hook';
import { HeadCellsCollection, GridLayout, DEFAULT_PAGINATION } from 'shared/ui/grid-layout';
import { InvoiceWccRow } from './invoice-wcc-row';
import { Box, Checkbox } from '@mui/material';
import { WccBulkCreateFilter } from 'features/wcc-bulk-create/wcc-bulk-create-filter';
import { useActionCreators, useStateSelector } from 'shared/store';
import { wccBulkCreateActions } from './slice';

export const BulkCreateList = () => {
  const [paging, setPaging] = useState(DEFAULT_PAGINATION);
  const [filterString, setFilterString] = useState('');

  const collection = useInvoicesWithoutWcc(paging, filterString);

  const checkedItems = useStateSelector(state => state.wcc.bulkCreate.checkedItems);
  const { toggleChecked, checkAll, uncheckAll } = useActionCreators(wccBulkCreateActions);


  const handleToggleAll = (e: any, checked: boolean) => {
    if (checked)
      checkAll(collection.collection.items.map(x => x.id));
    else
      uncheckAll();
  };

  const isAllChecked = () =>
    checkedItems.length !== collection.collection.items.length;

  const handleCheckChange = (id: number, checked: boolean) => {
    toggleChecked({ id, checked });
    // setCheckedItems(!checked ? checkedItems.filter(x => x !== id) : [...checkedItems, id]);
  };



  const columns: HeadCellsCollection = [
    {
      label: <Checkbox
        onChange={handleToggleAll}
        value={!isAllChecked()}
        indeterminate={isAllChecked() && checkedItems.length > 0} />,
      align: 'left',
      padding: 'checkbox'
    },
    'Счет',
    'ПП',
    'Дата окончания работ',
    'Наше юрлицо',
    'Клиент',
    'Менеджер',
    { label: 'Заявки', align: 'right' },
    { label: 'Сумма счета', align: 'right' },
    { label: 'Статус счета', align: 'right' },
  ];


  return (
    <>
      <Box my={1}>
        <WccBulkCreateFilter
          filterString={filterString}
          onFilter={setFilterString} />
      </Box>

      <GridLayout
        tableOptions={{ component: 'div' }}
        columns={columns}
        items={collection.collection.items}
        pagingData={paging}
        onChangePaging={setPaging}
        totalItemsCount={collection.collection.totalItemsCount}
        isLoading={collection.isLoading}
        noItemsText="Список счетов пуст"
        itemRender={(invoice) =>
          <InvoiceWccRow
            key={invoice.id}
            invoice={invoice}
            checked={checkedItems.some(x => x === invoice.id)}
            onCheckChange={(checked) => handleCheckChange(invoice.id, checked)} />}
      />
    </>
  );
};
