import { Type } from "class-transformer";
import {
    IsBoolean,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsString,
} from "class-validator";
import { ParseLogDto } from "./parser-log.dto";
import { UserDto } from "../user.dto";

export enum Interval {
  ONE_MINUTE = '1__min',
  TEN_MINUTES = '10__min',
  THIRTY_MINUTES = '30__min',
  ONE_HOUR = '1__hour',
  ONE_DAY = '24__hour',
}

export enum SupportedProviders {
  YANDEX = 'YANDEX',
  GOOGLE = 'GOOGLE', // никто никогда не тестировал как это будет работать
}

export class CreateEmailAccountDto {
    @IsNumber()
    userId: number;

    @IsNotEmpty()
    @IsString()
    email: string;
  
    @IsString()
    login: string;
  
    @IsString()
    password: string;

    @IsEnum(SupportedProviders)
    provider: SupportedProviders;

    @IsEnum(Interval)
    checkInterval: Interval;

    @IsBoolean()
    active: boolean;

    @IsBoolean()
    general: boolean;
  }
  
  export class EmailAccountDto extends CreateEmailAccountDto {
    @IsNumber()
    id: number;
  }

  export class EmailAccountLogParserDto extends CreateEmailAccountDto {
    @IsNumber()
    id: number;
    
    @Type(() => UserDto)
    user: UserDto;

    @Type(() => ParseLogDto)
    logs: ParseLogDto[];
  }

  export declare class UpdateEmailAccountDto {
    @IsNumber()
    id: number;

    @IsNumber()
    userId: number;

    @IsNotEmpty()
    @IsString()
    email: string;
  
    @IsString()
    login: string;
  
    @IsString()
    password: string;

    @IsEnum(SupportedProviders)
    provider: SupportedProviders;

    @IsEnum(Interval)
    checkInterval: Interval;
    
    @IsBoolean()
    active: boolean;

    @IsBoolean()
    general: boolean;
}