import { TableCell, TableRow } from '@mui/material';
import { ParseLogDto } from '@sr/dto';
import { GridLayout } from 'shared/ui/grid-layout';
import { useParseLog } from './email-parser.hooks';

const columns = [
    'Статус',
    'Отчет',
    'Дата создания',
  ];

  type Props = {
   id: number
}

export const LogParser = (value: Props) => {
    const { collection, isLoading, error } = useParseLog(value.id);
    return (
      <>
      <GridLayout<ParseLogDto>
        columns={columns}
        items={collection.items}
        isLoading={isLoading}
        error={error}
        noItemsText='По данной почте нет лога'
        itemRender={(item) => <ParseLogRow
          key={item.id}
          parseLog={item}   
          />}
      />
      </>
    );
  };
  
  type RowProps = {
    parseLog: ParseLogDto;
};

export const ParseLogRow = ({ parseLog }: RowProps) => {
return (
    <>
    <TableRow
        key={parseLog.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
        <TableCell>{parseLog.status}</TableCell>
        <TableCell>{parseLog.report}</TableCell>
        <TableCell>{String(parseLog.createdAt)}</TableCell>
    </TableRow>
    </>
)
};  


export default LogParser;