import { Box, FormControl, IconButton, InputLabel, Link, Stack, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import BackupIcon from '@mui/icons-material/Backup';
import { ReactNode, useCallback, useState } from 'react';
import { DownloadFileDto, isFile } from '@sr/dto';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { FieldProps } from 'formik';
import { fileNameCutter, LinkToFile } from '../links/link-to-file';
import Alert from '@mui/material/Alert';

const pickerTypes = ['image', 'doc', 'file'] as const;
type PickerTypes = typeof pickerTypes[number]

type PickerFieldProps =
  { label: ReactNode; disabled: boolean; multiple: boolean; pickerType?: PickerTypes; maxFileSize?: number }
  & FieldProps<File | DownloadFileDto | null | File[] | DownloadFileDto[], any>;
const FILE_SIZE_LIMIT = 5 * (1024 **2); // 5 Mb

const getAllowedTypes = (type: PickerTypes): string[] => {
  let allowedTypes: string[] = [];
  switch (type){
  case 'image':
    allowedTypes.push(...IMAGE_TYPES);
    break;
  case 'doc':
    allowedTypes.push(...DOCUMENT_TYPES);
    break;
  case 'file':
    allowedTypes.push(...IMAGE_TYPES, ...DOCUMENT_TYPES);
    break;
  default:
    throw Error(`File picker type "${type}" is not supported`);
  }
  return allowedTypes;
};

/**
 * @maxFileSize - размер файла в Mb
 * Образец использования свойства "pickerType"
 *                     <Field
 *                       name="offerFile"
 *                       label="Файл"
 *                       maxFileSize={10}   10-размер в Mb
 *                       pickerType="image"
 *                       component={SingleFilePickerField}
 *                       disabled={false}
 *                     />
 */

const FilePickerField = (props: PickerFieldProps) => {
  const {
    form: { setFieldValue, setFieldError, errors, setTouched, touched, validateField },
    field: { name, value },
    pickerType='file',
    maxFileSize,
    label,
    disabled,
    multiple = false
  } = props;

  const fileNames = (): ReactNode[] => {
    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      if (!value.length) return [];

      if (value[0] instanceof File) {
        return (value as File[]).map((item) => fileNameCutter(item.name));
      } else {
        return (value as DownloadFileDto[]).map((item, idx) => <span key={item.id}>{idx}.<LinkToFile  file={item} /></span>);
      }
    } else if (value instanceof File) {
      return [fileNameCutter(value.name)];
    }

    return [<LinkToFile key={value.id} file={value} />];
  };

  const onChange = async (event: any) => {
    const files = event?.currentTarget?.files;
    if (files) {
      await setFieldValue(name, multiple ? Array.from(files) : files[0]);
      await setTouched({ [name]: true }, false);

      for (const file of files) {
        if (file.size > FILE_SIZE_LIMIT && maxFileSize === undefined) {
          setFieldError(name,
            `Размер файла: ${Math.floor(file.size/(1024**2))}Mb больше допустимого ${Math.floor(FILE_SIZE_LIMIT/1024**2)}Mb`);
          return;
        }
        if (maxFileSize !== undefined && file.size > maxFileSize * (1024 **2)) {
          setFieldError(name,
            `Размер файла: ${Math.floor(file.size/(1024**2))}Mb больше допустимого ${Math.floor((maxFileSize * (1024 **2))/1024**2)}Mb`);
          return;
        }
        if (!getAllowedTypes(pickerType).includes(file.type)) {
          console.error('Данный формат файла не поддерживается: ', file.type);
          setFieldError(name, 'Данный формат файла не поддерживается');
        }
      }
    }

    event.target.value = null;
  };

  const showConfirm = useConfirmDialog();

  const clearFiles = useCallback((fileName: string) => {
    if (multiple && Array.isArray(value)) {
      const files = (value as File[]).filter((item) => item.name !== fileName);
      setFieldValue(name, files);
    } else {
      setFieldValue(name, null);
    }
  }, [multiple, name, setFieldValue, value]);

  const handleClearClick = useCallback((fileName: string) => {
    showConfirm({ content: 'Удалить файл?' }).then(() => {
      clearFiles(fileName);
    });
  }, [clearFiles, showConfirm]);

  const fileNamesElement = () => {
    if (!fileNames().length) {
      return null;
    }

    return (
      <Stack>
        {fileNames().map((item, idx) => {
          if (typeof item === 'string') {
            return (
              <Box key={idx}>
                {item}
                <IconButton
                  disabled={disabled}
                  onClick={() => handleClearClick(item)}>
                  <HighlightOffIcon />
                </IconButton>
              </Box>
            );

          }
          return item;
        })}
      </Stack>
    );
  };

  const acceptValue = (): string => {
    return pickerType === 'image'
      ? IMAGE_TYPES.join(',')
      : pickerType === 'doc'?
        DOCUMENT_TYPES.join(',')
        : '';
  };

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <FormControl>
          <InputLabel htmlFor="component-simple" shrink>{label}</InputLabel>
          <Box id="component-simple" minWidth={150} sx={{ p: 1, textOverflow: 'ellipsis' }}>
            {fileNamesElement() ||
              <Link>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <BackupIcon />
                  <Typography variant='body2'>
                    выбрать файл{multiple ? 'ы' : ''}...
                  </Typography>
                </Stack>
              </Link>
            }
          </Box>
        </FormControl>
        <IconButton component='label' color='primary' disabled={disabled}>
          <AttachFileIcon />
          <input id="file" name="file" accept={acceptValue()} type="file" onChange={onChange}
            multiple={multiple} hidden />
        </IconButton>
      </Stack>
      {touched[name] && errors[name] && (
        <Alert severity="error">
          { String(errors[name]) }
        </Alert>
      )}
    </Box>);
};

export default FilePickerField;


type Props = {
  label: string;
  value: File | DownloadFileDto | null;
  onChange: (file: File | null) => void;
  disabled?: boolean;
};

export const SingleFilePicker = ({ label, value, onChange, disabled }: Props) => {

  const handleChange = (event: any) => {
    if (event?.currentTarget?.files) {
      onChange(event.currentTarget.files[0]);
    }
  };

  return (
    <FormControl>
      <InputLabel htmlFor="component-simple" shrink>{label}</InputLabel>
      <Stack direction="row">

        <Box id="component-simple" minWidth={150} sx={{ p: 1, textOverflow: 'ellipsis' }}>
          {value
            ? isFile(value)
              ? value.name
              : value.fileName
            :
            <Link>
              <Stack direction='row' spacing={1} alignItems='center'>
                <BackupIcon />
                <Typography variant='body2'>
                  выбрать файл...
                </Typography>
              </Stack>
            </Link>
          }
          {/* {file && <IconButton onClick={handleClearClick}>
          <HighlightOffIcon />
        </IconButton>} */}
        </Box>
        <IconButton component='label' color='primary' disabled={disabled}>
          <AttachFileIcon />
          <input id="file" name="file" type="file" onChange={handleChange} hidden />
        </IconButton>
      </Stack>
    </FormControl>
  );
};

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
const DOCUMENT_TYPES = [
  'application/pdf',
  'application/rtf',
  'text/plain',
  'application/msword', // .doc
  'application/vnd.ms-excel', // .xls
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', //pptx
  'application/vnd.oasis.opendocument.presentation', // .odp
  'application/vnd.oasis.opendocument.spreadsheet', //ods
  'application/vnd.oasis.opendocument.text', //odt
];

const IMAGE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/tiff'
];
