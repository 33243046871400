export class ParsedEmailsDto {
  id: number;
  messageId: string;
  date: Date;
  subject: string;
  from: string;
  to: string;
  body: string;
  type: string;
  attachmentIds: string;
  accountId: number;
}
