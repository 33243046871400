import { PRINT_FORMS_BASE_URL } from 'shared/api/api';
import { authorizedRequest } from 'shared/api/axios';

export const fetchWccPrintForm = async (wccId: number, withStamp = false) => {
  const response = await authorizedRequest<void, string>(
    `${PRINT_FORMS_BASE_URL}/wcc/${wccId}?stamp=${withStamp ? 'true' : ''}`,
    'GET'
  );
  return response;
};

export const fetchTaxInvoicePrintForm = async (
  wccId: number,
  withStamp = false
) => {
  const response = await authorizedRequest<void, string>(
    `${PRINT_FORMS_BASE_URL}/wcc-invoice/${wccId}`,
    'GET'
  );
  return response;
};

export const fetchWccUpdInvoicePrintForm = async (
  wccId: number,
  withStamp = false
) => {
  const response = await authorizedRequest<void, string>(
    `${PRINT_FORMS_BASE_URL}/wcc-invoice/upd/${wccId}`,
    'GET'
  );
  return response;
};
