import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import { invoiceCloseApi } from './invoice-close.api';
import { InvoiceClosingDataDto } from '@sr/dto';
import { getCurrentPeriod } from 'entities/period-picker/period.helpers';
import { Period } from 'entities/period-picker/types';

export const useInvoiceClosingData = (invoiceId: number) => {
  const { data, isLoading, isFetching, error } = invoiceCloseApi.useGetInvoiceClosingDetailsQuery(
    invoiceId,
    { refetchOnMountOrArgChange: 120 }
  );

  return {
    invoiceClosingData: data
      ?? {
        managerCommission: null, managerPercent: 0, period: getCurrentPeriod()
      } satisfies Omit<InvoiceClosingDataDto, 'invoiceId' | 'managerId'>,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading || isFetching,
  };
};

export const useSetManagerPercent = (invoiceId: number) => {
  const [update, { isLoading }] = invoiceCloseApi.useSetManagerPercentageMutation();

  return {
    setPercentage: async (managerPercent: number) => {
      return update({ managerPercent, invoiceId }).unwrap();
    },
    isLoading,
  };
};

export const useSetInvoiceClosingPeriod = (invoiceId: number) => {
  const [update, { isLoading }] = invoiceCloseApi.useSetInvoiceClosingPeriodMutation();

  return {
    setPeriod: async (period: Period) => {
      return update({ period, invoiceId }).unwrap();
    },
    isLoading,
  };
};

export const useInvoiceControl = () => {
  const [close, { isLoading: isCloseLoading }] = invoiceCloseApi.useCloseInvoiceMutationMutation();
  const [open, { isLoading: isOpenLoading }] = invoiceCloseApi.useOpenInvoiceMutationMutation();

  return {
    closeInvoice: async (invoiceId: number) => {
      return close({ invoiceId }).unwrap().catch(e=>{ throw new Error(extractNestJsErrorMessage(e)) });
    },
    openInvoice: async (invoiceId: number) => {
      return open({ invoiceId }).unwrap().catch(e=>{ throw new Error(extractNestJsErrorMessage(e)) });
    },
    isLoading: isCloseLoading || isOpenLoading,
  };
};
