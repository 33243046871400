import { useMemo } from 'react';
import { Box, Paper, Stack } from '@mui/material';
import { LeadPropsForm } from 'features/lead/lead-props-form/lead-props-form';
import { useTitleHook } from 'hooks/page-title.hook';
import { useIdParam } from 'utils/url-paramters.hook';
import { PageHeader } from 'shared/ui/details-page-header';
import { formatDateTime } from 'utils/date-format-helpers';
import { LeadContentWidget } from 'widgets/lead/lead-content';
import { LeadSubheader } from 'entities/lead/lead-subheader';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { LeadMediaPlan } from 'features/lead/lead-mediaplan/lead-mediaplan';
import { TabInfo, Tabs } from 'shared/ui/tabs';
import { LeadHeaderActions } from 'features/lead/lead-header/lead-header-actions';
import { leadApi } from 'features/lead/api';
import { ContactsPanel } from 'widgets/contacts/contact-panel';
import { EntitiesWithContacts } from 'features/contact/contacts.types';
import { LeadCompanies } from 'features/lead/lead-companies';
import EventItem from '../../components/Companies/Feed/event-item';

export const LeadDetailsPage = () => {
  const leadId = useIdParam('leadId');

  const { data: lead } = leadApi.useGetLeadQuery(leadId);

  useTitleHook(lead && `Лид ${leadId} от ${formatDateTime(lead.createdAt)}`);

  const tabs: TabInfo[] = useMemo(() =>
    lead ? [
      {
        label: 'Лид',
        // icon: ReactElement;
        component: <LeadPropsForm lead={lead}/>
      },
      {
        label: 'Медиаплан',
        // icon: ReactElement;
        component: <LeadMediaPlan lead={lead}/>
      },
      {
        label: 'Связи',
        // icon: ReactElement;
        component: <LeadCompanies leadId={lead.id}/>,
      },
      {
        label: 'Дела',
        // icon: ReactElement;
        component: <>Дела</>,
        disabled: true
      },
      {
        label: 'Счета',
        // icon: ReactElement;
        component: <>Счета</>,
        disabled: true
      }
    ] : [],
  [lead]
  );

  if (!lead)
    return <LoadingBanner/>;

  return (
    <>
      <PageHeader
        title={<PageHeader.Text>Лид № {lead.id}: {lead.subject}</PageHeader.Text>}
        subHeader={<LeadSubheader lead={lead}/>}
        actions={<>
          <LeadHeaderActions lead={lead}/>
        </>}

      />

      <Stack direction='row' spacing={2}>
        <Stack sx={{ flexGrow: 1 }} direction='column'>
          <Box sx={{ p: 1 }}>
            <ContactsPanel relationId={leadId} relationType={EntitiesWithContacts.lead}/>
          </Box>
          <LeadContentWidget lead={lead}/>
          <Stack direction='column' spacing={1} sx={{ mt: 2 }}>
            {lead.events.map((event, idx)=>
              <EventItem
                showCompany={true}
                key={event.id}
                item={event}
              />
            )}
          </Stack>
        </Stack>
        <Stack>
          <Paper sx={{ p: 1 }}>
            <Tabs tabs={tabs}/>
          </Paper>
        </Stack>
      </Stack>
    </>
  );
};


export default LeadDetailsPage;
