import { Divider, Typography, useTheme } from '@mui/material';
import { LegendList, LegendItem } from 'shared/ui/legend-list';
import PriceSpan from 'shared/ui/price-span';
import { PaidProgress } from 'shared/ui/progress-bar';
import { CardPaper } from 'shared/ui/card-paper';
import { InvoiceReportTotals } from './invoice-report-rows';
import { getProfitabilityRangeColor } from './lib';

type ProfitProps = {
  profits: InvoiceReportTotals
  showProfits: boolean
};

export const ProfitCard = ({ profits, showProfits }: ProfitProps) => {
  const ramaining = profits.buyerRowsTotal - (profits.buyerPartialPaidTotal ?? 0);
  const profitability = profits.buyerRowsTotal !== 0 ? (100 * profits.totalProfitByPurchases / profits.buyerRowsTotal) : undefined;
  const theme = useTheme();
  return (
    <CardPaper>
      <LegendList>
        <PaidProgress
          variant="determinate"
          value={100 * (profits.buyerRowsTotal ? ((profits.buyerPartialPaidTotal ?? 0) / profits.buyerRowsTotal) : 0)}
        />
        <LegendItem title="Сумма:" value={<PriceSpan price={profits.buyerRowsTotal} />} />
        <LegendItem title="Оплачено:" value={<PriceSpan price={profits.buyerPartialPaidTotal ?? 0} />} />
        <LegendItem title="Еще:" value={<PriceSpan price={ramaining} />} />
        {showProfits &&
          <>
            <Divider sx={{ p: 1 }} />
            <LegendItem title="Прибыль по заявкам:" value={<PriceSpan price={profits.totalProfitByPurchases} coloring />} />
            {!!profits.totalProfitByPayments &&
              <LegendItem title="Прибыль по платежкам:" value={<PriceSpan isApproximate coloring price={profits.totalProfitByPayments} />} />}

            <LegendItem title="Рентабельность:" value={
              profitability !== undefined ?
                <Typography variant='h6' fontWeight={500} component='span' color={getProfitabilityRangeColor(profitability, theme)}>
                  {profitability.toFixed(0)}%
                </Typography>
                : '-'
            } />

          </>}
      </LegendList >
    </CardPaper>
  );
};
