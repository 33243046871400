import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CreateEmailAccountDto, EmailAccountDto, EmailAccountLogParserDto, IPagedCollection, ParseLogDto, UpdateEmailAccountDto } from '@sr/dto';
import { APP_BASE_URL } from 'shared/api/api';
import { CollectionParams, setAuthHeader } from 'shared/api/rtk-query';
import { pagingDataToParams } from 'utils/remote-paged-collection.hook';

export const emailParserApi = createApi({
  reducerPath: 'email-parser',
  tagTypes: ['email-parser'],
  baseQuery: fetchBaseQuery({
    baseUrl: APP_BASE_URL,
    prepareHeaders: (headers, api) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getEmailParserAccouns: builder.query<IPagedCollection<EmailAccountLogParserDto>, CollectionParams>({
      query: ({paging, filter}: CollectionParams) => ({
        url: `email-parser/?${filter}`,
        params: paging && pagingDataToParams(paging)
      }),
      providesTags: (result, error, query) =>
        ([{ type: 'email-parser', id: 'LIST' }])
    }),
  getEmailAccount: builder.query<EmailAccountLogParserDto, number>({
    query: (emailAccountId) => ({
      url: `email-parser/${emailAccountId}`,
      method: 'GET',
    }),
    providesTags: [{type: "email-parser", id: "LIST"}], 
  }),
  createEmailAccountType: builder.mutation<EmailAccountDto, CreateEmailAccountDto>({
    query: (dto ) => ({
      url: `email-parser/create`,
      body: dto,
      method: 'POST',
    }),
      invalidatesTags: (result, error, query) =>
        ([{ type: 'email-parser', id: "LIST" }])
}),
  updateEmailAccount: builder.mutation<EmailAccountDto, UpdateEmailAccountDto>({
    query: ({ ...dto }) => ({
      url: `email-parser/update`,
      body: dto,
      method: 'PUT',
    }),
    invalidatesTags: (result, error, query) => [{ type: 'email-parser', id: "LIST" }],
  }),
  getParseLog: builder.query({
    query: (accountId: number) => ({
      url: `email-parser/${accountId}/parse-log`,
      method: 'GET',
    }),
  }),
  })
})