import { Box, Button, MenuItem, Stack } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { LegalEntityPicker } from 'shared/ui/formik-fields/legal-entity-picker';
import { Field, Formik } from 'formik';
import { CardPaper } from 'shared/ui/card-paper';
import { useOurCompany } from 'hooks/portal-settings.hook';
import { endOfDay, startOfDay } from 'date-fns';
import { UserPickerField } from 'features/user/user-picker/ui';
import { useSearchParams } from 'react-router-dom';
import { FilterFormValues, fromDateString, fromNumberString, PURCHASE_STATUSES, PurchaseFilter } from '@sr/dto';
import { TextField } from 'formik-mui';

type FormValues = FilterFormValues<PurchaseFilter>;

const initial: FormValues = {
  ourLegalEntityId: '',
  fromDate: null,
  toDate: null,
  statusId: '',
  managerId: '',
};

const fromUrlSearchParams = (params: URLSearchParams) => {
  const formValues: FormValues = {
    ourLegalEntityId: fromNumberString(params.get('ourLegalEntityId')),
    fromDate: fromDateString(params.get('fromDate')),
    toDate: fromDateString(params.get('toDate')),
    statusId: fromNumberString(params.get('statusId')),
    managerId: fromNumberString(params.get('managerId')),
  };

  return formValues;
};

const toSearchString = (formValues: FormValues) => {
  const builder = new URLSearchParams();
  formValues.ourLegalEntityId && builder.append('ourLegalEntityId', formValues.ourLegalEntityId.toString());
  formValues.fromDate && builder.append('fromDate', startOfDay(formValues.fromDate).toString());
  formValues.toDate && builder.append('toDate', endOfDay(formValues.toDate).toString());
  formValues.statusId && builder.append('statusId', formValues.statusId.toString());
  formValues.managerId && builder.append('managerId', formValues.managerId.toString());

  return builder.toString();
};

type Props = {
    filterString: string,
    onFilter: (searchParam: string) => void;
};

export const PurchasesFilter = ({ filterString, onFilter }: Props) => {
  const ourCompanyId = useOurCompany();

  const handleSubmit = async (formValues: FormValues) => {
    const filterString = toSearchString(formValues);

    const allCheckBoxes = document.querySelectorAll('input[class=\'purchases\']') as NodeListOf<HTMLInputElement>;
    allCheckBoxes.forEach(checkBox => {
      checkBox.checked = false;
    });

    return onFilter(filterString);
  };

  return (
    <CardPaper>
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={
          filterString === ''
            ? initial
            : fromUrlSearchParams(new URLSearchParams(filterString))
        }
      >{
          ({ submitForm, resetForm, dirty }) => {
            return (
              <Stack direction="row" spacing={1}>
                <Box sx={{ minWidth: 200, flexGrow: 1 }}>

                  <Field
                    label='Статус'
                    fullWidth
                    component={TextField}
                    select
                    name='statusId'
                  >
                    <MenuItem
                      key=""
                      value=""
                    >
                                        &nbsp;
                    </MenuItem>
                    {PURCHASE_STATUSES.map((s) => (
                      <MenuItem
                        key={s.id}
                        value={s.id}
                      >
                        {s.label}
                      </MenuItem>
                    ))}
                  </Field>
                </Box>

                <Box sx={{ minWidth: 200, flexGrow: 1 }}>
                  <Field
                    fullWidth
                    name="ourLegalEntityId"
                    label="Наше юрлицо"
                    component={LegalEntityPicker}
                    companyIdFilter={ourCompanyId}
                  />
                </Box>
                <Box sx={{ minWidth: 200, flexGrow: 0 }}>
                  <Field
                    fullWidth
                    name="fromDate"
                    label="Дата создания с"
                    component={DatePickerField}
                  />
                </Box>
                <Box sx={{ minWidth: 200, flexGrow: 0 }}>
                  <Field
                    fullWidth
                    name="toDate"
                    label="Дата создания по"
                    component={DatePickerField}
                  />
                </Box>

                <Box sx={{ minWidth: 200, flexGrow: 1 }}>
                  <Field
                    fullWidth
                    name="managerId"
                    component={UserPickerField}
                  />
                </Box>

                <Button
                  onClick={submitForm}
                  variant="contained"
                  disabled={!dirty}
                  endIcon={<FilterListIcon/>}
                >
                                Применить
                </Button>

                <Button
                  onClick={() => resetForm()}
                  variant="outlined"
                  endIcon={<HighlightOffIcon/>}
                >
                                очистить
                </Button>
              </Stack>
            );
          }
        }
      </Formik>
    </CardPaper>
  );
};

export const useInvoicesFilterSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return fromUrlSearchParams(searchParams);
};
