import { useState } from 'react';
import { WccDto } from '@sr/dto';
import { HeadCellsCollection, GridLayout, DEFAULT_PAGINATION } from 'shared/ui/grid-layout';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { useActionCreators, useStateSelector } from 'shared/store';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import ManagerSpan from 'shared/ui/manager-span';
import PriceSpan from 'shared/ui/price-span';
import { formatDate } from 'utils/date-format-helpers';
import { useWccListToExport } from './lib';
import { LinkToWcc } from 'shared/ui/links/link-to-wcc';
import { wccExportActions } from './store-slice';
import { WccStatusSpan } from 'entities/wcc/wcc-status-span';

export const ExportList = () => {
  const [paging, setPaging] = useState(DEFAULT_PAGINATION);
  const [filterString, setFilterString] = useState('');

  const collection = useWccListToExport(paging);

  const checkedItems = useStateSelector(state => state.wcc.export.checkedItems);
  const { toggleChecked, checkAll, uncheckAll } = useActionCreators(wccExportActions);


  const handleToggleAll = (e: any, checked: boolean) => {
    if (checked)
      checkAll(collection.collection.items.map(x => x.id));
    else
      uncheckAll();
  };

  const isAllChecked = () =>
    checkedItems.length !== collection.collection.items.length;

  const handleCheckChange = (id: number, checked: boolean) => {
    toggleChecked({ id, checked });
    // setCheckedItems(!checked ? checkedItems.filter(x => x !== id) : [...checkedItems, id]);
  };



  const columns: HeadCellsCollection = [
    {
      label: <Checkbox
        onChange={handleToggleAll}
        value={!isAllChecked()}
        indeterminate={isAllChecked() && checkedItems.length > 0} />,
      align: 'left',
      padding: 'checkbox'
    },
    'Дата акта',
    'Акт',
    'Счет',
    'Наше юрлицо',
    'Контрагент',
    'Менеджер',
    { label: 'Сумма счета', align: 'right' },
    'Статус счета'
  ];


  return (
    <>
      {/* <Box my={1}>
        <WccBulkCreateFilter
          filterString={filterString}
          onFilter={setFilterString} />
      </Box> */}

      <GridLayout
        tableOptions={{ component: 'div' }}
        columns={columns}
        error={collection.error}
        items={collection.collection.items}
        pagingData={paging}
        onChangePaging={setPaging}
        totalItemsCount={collection.collection.totalItemsCount}
        isLoading={collection.isLoading}
        noItemsText="Список счетов пуст"
        itemRender={(wcc) =>
          <WccRow
            key={wcc.id}
            wcc={wcc}
            checked={checkedItems.some(x => x === wcc.id)}
            onCheckChange={(checked) => handleCheckChange(wcc.id, checked)} />}
      />
    </>
  );
};



type Props = {
  wcc: WccDto;
  checked: boolean;
  onCheckChange: (checked: boolean) => void;
};

const WccRow = ({ wcc, checked, onCheckChange }: Props) => {
  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={checked}
          onChange={(e, checked) => onCheckChange(checked)}
        />
      </TableCell>
      <TableCell>
        {formatDate(wcc.date)}
      </TableCell>
      <TableCell>
        <LinkToWcc wcc={wcc} />
      </TableCell>
      <TableCell>
        <LinkToInvoice invoice={wcc.invoice} />
      </TableCell>
      <TableCell>
        <LinkToLegalEntity legalEntity={wcc.invoice.seller} />
      </TableCell>
      <TableCell>
        <LinkToLegalEntity legalEntity={wcc.invoice.buyer} />
      </TableCell>
      <TableCell>
        <ManagerSpan person={wcc.invoice.manager} />
      </TableCell>
      <TableCell align='right'>
        <PriceSpan price={wcc.invoice.totals.discountPriceTotal} size='small' />
      </TableCell>
      <TableCell>
        <WccStatusSpan status={wcc.status} />
      </TableCell>
    </TableRow>
  );
};
