import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { WccDto } from '@sr/dto';
import { Button, Divider, Grid, MenuItem, Paper } from '@mui/material';
import Popper from '@mui/material/Popper';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import { fetchInvoicePrintForm } from '../client-invoices-list/api';
import { fetchTaxInvoicePrintForm } from 'features/wcc/wcc-print-form/api';
import { fetchWccUpdInvoicePrintForm } from 'features/wcc/wcc-print-form/api';
import { usePrintPopup } from 'utils/print-popup.hook';
import { formatDate } from 'utils/date-format-helpers';
import { useSnack } from 'shared/ui/snack';
import PriceSpan from 'shared/ui/price-span';
import { useInvoiceWcc } from 'features/invoice/invoice-wcc/lib';
import { WCC_STATUS_STYLE, WccStatusSpan } from './wcc-status-span';

type Props = {
  initialWcc: WccDto | null
  invoiceId: number
}

function WccTitle({ wcc }: {wcc:WccDto}) {
  return     <>
    <Divider textAlign="left">Общее</Divider>
    <Grid container direction="column" sx={{ pl: 2 }}>
      <Grid item>
        <Typography  variant="caption">Сумма: <PriceSpan size='small' price={wcc.amount}/></Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">Дата: {formatDate(wcc.date)}</Typography>
      </Grid>
      <Grid item>
        <WccStatusSpan status={wcc.status} size="small"/>
      </Grid>
    </Grid></>;
}

function WccPrintButton({ invoiceId, withStamp = false }: { invoiceId: number, withStamp?: boolean }) {
  const { showError } = useSnack();

  const { openWindow } = usePrintPopup(
    useCallback(() => {
      return fetchInvoicePrintForm(invoiceId, withStamp)
        .catch(e => {
          showError(`Ошибка получения печатной формы: ${e}`);
          return '';
        });
    },
    [invoiceId, showError])
  );
  return <MenuItem dense onClick={openWindow}>Акт {withStamp ? '(с печатью)': ''}</MenuItem>;
}

function TaxInvoicePrintButton({ invoiceId }: { invoiceId: number }) {
  const { showError } = useSnack();

  const { openWindow } = usePrintPopup(
    useCallback(() => {
      return fetchTaxInvoicePrintForm(invoiceId)
        .catch(e => {
          showError(`Ошибка получения печатной формы: ${e}`);
          return '';
        });
    },
    [invoiceId, showError])
  );
  return <MenuItem dense onClick={openWindow}>Счет фактура</MenuItem>;
}

function WccUpdInvoicePrintButton({ wccId }: { wccId: number }) {
  const { showError } = useSnack();

  const { openWindow } = usePrintPopup(
    useCallback(() => {
      return fetchWccUpdInvoicePrintForm(wccId)
        .catch(e => {
          showError(`Ошибка получения печатной формы: ${e}`);
          return '';
        });
    },
    [wccId, showError])
  );
  return <MenuItem dense onClick={openWindow}>УПД</MenuItem>;
}

export function WccButton({ initialWcc, invoiceId }: Props) {
  const { showSuccess, showError } = useSnack();
  const { invoiceWcc: wcc, createWcc, deleteWcc, isFetchingWcc, isPostingWcc } = useInvoiceWcc({ invoiceId, initialWcc });
  const handleCreateWcc = async () => {
    return createWcc()
      .then(() => showSuccess('Акт выполненных работ успешно создан'))
      .catch(e => showError(`Ошибка создания акта выполненных работ: ${e}`));
  };

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
  if (!wcc) {
    return <Button onClick={handleCreateWcc} disabled={isPostingWcc}>Сформировать</Button>;
  }

  return (
    <>
      <ButtonGroup
        size="small"
        ref={anchorRef}
        sx = {{ display: 'flex', }}
      >
        <Button
          color={WCC_STATUS_STYLE[wcc.status].buttonColor}
          variant={WCC_STATUS_STYLE[wcc.status].buttonVariant}
          sx = {{ whiteSpace: 'nowrap' }}
          onClick={()=>navigate(`/wcc/${wcc.id}`)}>Акт: {wcc.wccNumber}</Button>
        <Button
          color={WCC_STATUS_STYLE[wcc.status].buttonColor}
          variant={WCC_STATUS_STYLE[wcc.status].buttonVariant}
          sx = {{ width: 20 }}
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <WccTitle wcc={wcc}/>
                  <Divider textAlign="left">Печать</Divider>
                  <WccPrintButton invoiceId={invoiceId}/>
                  <WccPrintButton invoiceId={invoiceId} withStamp={true}/>
                  <WccUpdInvoicePrintButton wccId={wcc?.id}/>
                  <TaxInvoicePrintButton invoiceId={invoiceId}/>
                  <Divider textAlign="left">Действия</Divider>
                  <MenuItem dense onClick={()=>deleteWcc()}>Удалить</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
