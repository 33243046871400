import { LegalEntityActiveStatusDto } from '@sr/dto';
import { legalEntitiesApi } from '../legal-entities.api';
import { LEGAL_ENTITY_BASE_URL } from '../../../shared/api/api';

const injectedApi = legalEntitiesApi.injectEndpoints({
  endpoints: (builder) => ({
    getLegalEntityActiveStatus: builder.query<LegalEntityActiveStatusDto, number>({
      query: (legalEntityId: number) => `${LEGAL_ENTITY_BASE_URL}/${legalEntityId}/active-status`,
    }),
    updateLegalEntityActiveStatus: builder.mutation<LegalEntityActiveStatusDto, { legalEntityId: number, data: LegalEntityActiveStatusDto }>({
      query: ({ legalEntityId, data }) => ({
        url: `${LEGAL_ENTITY_BASE_URL}/${legalEntityId}/active-status`,
        body: data,
        method: 'PUT'
      }),
    }),
  }),
});

export const legalEntitiesActiveStatusApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['legalEntityActiveStatus'],
  endpoints: {
    getLegalEntityActiveStatus: {
      providesTags: (result, _, legalEntityId) => (
        result ? [{ type: 'legalEntityActiveStatus', id: legalEntityId }] : ['legalEntityActiveStatus']
      ),
    },
    updateLegalEntityActiveStatus: {
      invalidatesTags: (res, error, query) => (
        res && !error ? [{ type: 'legalEntityActiveStatus', id: query.legalEntityId }] : ['legalEntityActiveStatus']
      ),
    },
  },
});
