import * as React from 'react';
import { IconButton, TableCell, TableFooter, TableRow, Typography } from '@mui/material';
import { useDeleteSalaryEntry, useSalaryData } from 'entities/salary/salary-entries/salary-entries.hook';
import { ADDITION_TYPES, DEDUCTION_TYPES, SALARY_ENTRY_TYPES, SalaryEntryDto } from '@sr/dto';
import { GridLayout } from 'shared/ui/grid-layout';
import { formatDateTime } from 'utils/date-format-helpers';
import { salaryTypesObj } from './salary-entry-types';
import PriceSpan from 'shared/ui/price-span';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnack } from 'shared/ui/snack';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import EditIcon from '@mui/icons-material/Edit';
import { useDeletePrepayment } from '../../prepayment/prepayment.hook';
import { useInvoiceControl } from '../../invoice-report/invoice-close/invoice-close.hook';


const columns = [
  'Тип', 'Сумма', 'Описание', 'Внес', 'Дата внесения', 'Действия',
];

const SalaryRow = ({ row, onDeleteClick, onEditClick }: {
  row: SalaryEntryDto,
  onDeleteClick: (row: SalaryEntryDto) => Promise<void>,
  onEditClick: (salaryEntryId: number)=>void,
}) => {

  return (
    <TableRow>
      <TableCell>
        {salaryTypesObj[row.type] ?? '-- неизвестный тип --'}
      </TableCell>
      <TableCell align="right">
        <PriceSpan price={row.sum} size="small" />
      </TableCell>
      <TableCell>
        {row.description}
      </TableCell>
      <TableCell>
        {row.createdBy.secondName} {row.createdBy.firstName}
      </TableCell>
      <TableCell>
        {formatDateTime(row.createdAt)}
      </TableCell>
      <TableCell align="right" sx={{ pr: 5 }}>
        {row.type === SALARY_ENTRY_TYPES.PREPAYMENT
          && <IconButton onClick={() => onEditClick( row.id )}>
            <EditIcon />
          </IconButton>}
        <IconButton onClick={() => onDeleteClick(row)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>);
};

const SalaryTotal = (rows: SalaryEntryDto[]) => {
  const total = rows.reduce<number>((acc, row) => {
    if (ADDITION_TYPES.includes(row.type)) {
      return acc + row.sum;
    }
    if (DEDUCTION_TYPES.includes(row.type)) {
      return acc - row.sum;
    }
    throw new Error('Salary Total Calculation not possible. Unknown type');
  }, 0);
  return (
    <TableFooter>
      <TableRow>
        <TableCell><Typography variant="subtitle1">Итого:</Typography> </TableCell>
        <TableCell align="right"><PriceSpan price={total} size="small" /></TableCell>
      </TableRow>
    </TableFooter>
  );
};

export const SalaryEntriesGrid = ({ year, month, userId, onEditClick }: {
  year: number,
  month: number,
  userId: number,
  onEditClick: (salaryEntryId: number)=> void
}) => {
  const { rows, isLoading, error } = useSalaryData(year, month, userId);
  const deleteSalaryEntry = useDeleteSalaryEntry(year, month, userId);
  const deletePrepayment = useDeletePrepayment();
  const { openInvoice } = useInvoiceControl();
  const { showSuccess, showError } = useSnack();

  const handleRowDelete = async (row: SalaryEntryDto) => {

    if (row.type === SALARY_ENTRY_TYPES.PREPAYMENT) {
      await deletePrepayment(row.id)
        .then(() => { showSuccess('Аванс успешно удален') })
        .catch(e => { showError('Ошибка удаления: ' + extractNestJsErrorMessage(e)) });
      return;
    }

    if (row.type === SALARY_ENTRY_TYPES.COMMISSION) {
      if (!row.invoiceId) {
        throw new Error('Начисление процентов без номера счета');
      }
      await openInvoice(row.invoiceId)
        .then(() => { showSuccess('Счет успешно открыт') })
        .catch(e => { showError('Ошибка открытия счета: ' + extractNestJsErrorMessage(e)) });
      return;
    }

    await deleteSalaryEntry(row.id)
      .then(() => { showSuccess('Запись успешно удалена') })
      .catch(e => { showError('Ошибка удаления: ' + extractNestJsErrorMessage(e)) });
  };

  const handleRowEdit = (salaryEntryId: number) => {
    onEditClick(salaryEntryId);
  };

  return <GridLayout
    columns={columns}
    items={rows}
    isLoading={isLoading}
    error={error}
    footer={SalaryTotal}
    itemRender={(row) => <SalaryRow key={row.id} row={row} onDeleteClick={handleRowDelete}
      onEditClick={handleRowEdit} />}
    noItemsText="У выбраного пользователя нету начислений/удержаний в выбранном периоде" />;
};
