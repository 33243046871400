import { InvoiceDto, InvoiceRowDto, PurchaseDto } from '@sr/dto';
import React from 'react';
import { TextField } from 'formik-mui';
import { Field, Form, Formik, FormikProps } from 'formik';
import { Box, Button, Card, CardContent, MenuItem, Stack, Typography } from '@mui/material';
import { RelatedPayorders } from 'entities/invoice-report/related-payorders';
import { useRelatedPayOrders } from 'entities/invoice-report/related-payorders.hook';
import { createPayOrderFromPurchase } from 'shared/api/purchases';
import { useSnack } from 'shared/ui/snack';

type Props = {
  invoice: InvoiceDto,
  invoiceRow: InvoiceRowDto;
  purchase: PurchaseDto;
};

export type FormData = {
  amountOption: number,
  amount: number;
}

type ValidationData = Partial<{ [x in keyof FormData]: string }>;

const initialValues: FormData = {
  amountOption: 0,
  amount: 0,
};

const NO_PAY_ORDER_PAYMENT_OPTIONS = new Map<string, number>([
  [
    '',
    0,
  ],
  [
    '100%',
    1,
  ],
  [
    '50%',
    0.5,
  ],
  [
    '30%',
    0.3,
  ]],
);

const DEFAULT_PAYMENT_OPTIONS = new Map<string, number>(
  [
    [
      '',
      0,
    ],
    ['Остаток', 1],
  ]
  ,
);

export const PurchasePayOrderForm = (props: Props) => {
  const { payOrders } = useRelatedPayOrders(props.invoice.id);
  const { showSuccess, showError } = useSnack();

  const handleSubmit = (values:FormData) => {
    createPayOrderFromPurchase(props.purchase.id,{ amount:values.amount })
      .then(async () => {
        payOrders.reload();
        showSuccess('Платежное поручение успешно создано');
      })
      .catch(e => showError(`Ошибка создания платежного поручения: ${e}`));
  };

  const validate = (values: FormData): ValidationData => {
    let validationResult: ValidationData = {};

    if (!values.amount) {
      validationResult.amount = 'Не указана сумма платежки';
    }

    const maxTotalAmount = Math.max(0,props.invoice.totals.priceTotal - props.invoice.totals.partialPaidAmount);

    if (values.amount > maxTotalAmount) {
      validationResult.amount = `Остаток по счёту: ${maxTotalAmount}`;
    }

    return validationResult;
  };

  const handleAmountOptionChange = (event: any, values: FormData, handleChange: (e: any) => void) => {
    const value = Number((event.target as HTMLSelectElement).value);
    values.amount = value * (props.purchase.supplierInvoiceRow.priceDiscount - props.invoice.totals.partialPaidAmount);
    handleChange(event);
  };

  return (
    <>
      <Box marginTop={2}>
        <Typography variant="h6" gutterBottom>Платежные поручения</Typography>
        <RelatedPayorders payOrders={payOrders}  referenceInvoiceId={props.invoice.id}/>
      </Box>
      <Card sx={{ my: 3, maxWidth: 400 }}>
        <CardContent>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validate}
          >
            {({ submitForm, isSubmitting, values, handleChange }: FormikProps<FormData>) => (
              <Form>
                <Stack direction="column" spacing={2}>

                  <Field onChange={(e: any) => handleAmountOptionChange(e, values, handleChange)} label="Процент оплаты"
                    component={TextField} select name="amountOption">
                    {
                      Array.from((payOrders.items.length ? DEFAULT_PAYMENT_OPTIONS : NO_PAY_ORDER_PAYMENT_OPTIONS).entries()).map((
                        [key, value],
                      ) => <MenuItem sx={{ height: 36 }} key={value} value={value}>{key}</MenuItem>,
                      )}
                  </Field>

                  <Field
                    required
                    disabled={values.amountOption !== 0}
                    label="Сумма оплаты"
                    component={TextField}
                    name="amount"
                  />
                  <Button onClick={submitForm} variant="contained" sx={{ ml: 'auto', mr: 1 }}>Сохранить</Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </>
  );
};
