import { TextField, Autocomplete, TextFieldProps as MuiTextFieldProps, Popper, Stack, styled, Typography } from '@mui/material';
import { InvoiceInfoDto, InvoiceStatus, InvoiceType } from '@sr/dto';
import { useInvoicesDropdown } from 'entities/client-invoices-list/lib/dropdown.hook';
import { InvoiceStatusSpan } from 'entities/invoice/status-span/status-span';
import { fieldToTextField, TextFieldProps } from 'formik-mui';
import { ChangeEvent, HTMLAttributes, ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { formatDate } from 'utils/date-format-helpers';

const OptionElement = ({ invoice, props }: {invoice: InvoiceInfoDto, props: HTMLAttributes<HTMLElement>}) => {
  return (
    <Stack {...props} direction='column' alignItems='flex-start' justifyContent='flex-start' width='100%'>
      <Stack direction="row" spacing={1.1} sx={{ width: '100%' }} alignItems="center" justifyContent="flex-start">
        <Typography align='left'>{invoice.invoiceNumber} от {formatDate(invoice.startDate)}</Typography>
        <InvoiceStatusSpan  status={invoice.status} size='small'/>
      </Stack>
      <Stack direction="row" spacing={1} sx={{ width: '100%' }} >
        <Stack direction='column'>
          <Typography fontSize={11} color={'gray'}>Продавец: {invoice.seller.name} ({invoice.seller.inn})</Typography>
          <Typography fontSize={11} color={'gray'}>Покупатель: {invoice.buyer.name} ({invoice.buyer.inn})</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const InvoicePopper = styled(Popper)({
  width: '370px !important',
});

type Props = TextFieldProps & {
  label: ReactNode;
  value: number | '';
  disabled?: boolean;
  textFieldProps?: MuiTextFieldProps;
  buyerLegalEntityId?: number;
  sellerLegalEntityId?: number;
  referenceInvoiceId?: number;
  statusIds?: InvoiceStatus[];
  invoiceType?: InvoiceType;
};

/**
 * @param {ReactNode} label
 * @param {boolean} disabled 
 * @param {InvoiceStatus[]} statusIds - статусы счета
 * @param {InvoiceType} invoiceType - тип счета (входящий / исходящий)
 * @param {number} referenceInvoiceId - id счета, по значениям sellerLegalEntityId и buyerLegalEntityId которго будут отфильтрованы счета
 * @param {number} buyerLegalEntityId - id юрлица покупателя
 * @param {number} sellerLegalEntityId - id юрлица продавца
 */
export const InvoicePicker = ({ label, onChange, disabled, referenceInvoiceId, statusIds, invoiceType, buyerLegalEntityId, sellerLegalEntityId,  ...props }: Props) => {
  const { form: { setFieldValue }, field: { name, value } } = props;
  const [open, setOpen] = useState(false);
  const [invoiceNumberFilter, setInvoiceNumberFilter] = useState('');
  const [selectedEntity, setSelectedEntity] = useState<InvoiceInfoDto | null>(null);
  const { items, isLoading } = useInvoicesDropdown({ invoiceNumber: invoiceNumberFilter,
    sellerLegalEntityId, buyerLegalEntityId, type: invoiceType, statusIds, referenceInvoiceId 
  });

  useEffect(() => {
    value && setSelectedEntity(items.find(x => x.id === value) ?? null);
  }, [items, value]);

  const handleChange = useCallback(
    (e: SyntheticEvent<Element, Event>, newValue: InvoiceInfoDto | null) => {
      setSelectedEntity(newValue);
      setFieldValue(name, newValue?.id);
    },
    [setSelectedEntity, onChange]
  );

  const onInput = async (event: ChangeEvent<HTMLInputElement>) => {
    setInvoiceNumberFilter(event.target.value);
  };

  const loading = open && items.length === 0 && isLoading;

  return (
    <Autocomplete
      value={loading ? null : selectedEntity}
      autoComplete={false}
      options={items}
      open={open}
      disabled={disabled}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={handleChange}
      onInput={onInput}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => `${option.invoiceNumber} от ${formatDate(option.startDate)}`}
      loading={loading}
      loadingText={'Загрузка...'}
      noOptionsText={'Ничего не найдено'}
      fullWidth
      PopperComponent={(props)=> <InvoicePopper {...props}/> }
      renderInput={
        (params) => <TextField
          {...fieldToTextField(props)}
          {...params}
          label={label}
          placeholder="Начните набирать..." />
      }
      renderOption={
        (props, option) => <OptionElement key={option.id} invoice={option} props={props} />
      }
    />
  );
};


