import { Chip } from '@mui/material';
import { WCC_STATUSES_MAP, WccStatus } from '@sr/dto';
import DoneIcon from '@mui/icons-material/Done';
import { ReactElement } from 'react';

type AppearanceParams = {
  spanColor: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default',
  spanVariant: 'filled' | 'outlined',
  buttonColor: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit',
  buttonVariant: 'text' | 'outlined' | 'contained',
  icon?: ReactElement,
}

export const WCC_STATUS_STYLE: Record<WccStatus, AppearanceParams> =
  {
    [WccStatus.READY]: {
      spanColor: 'default',
      spanVariant: 'outlined',
      buttonColor: 'inherit',
      buttonVariant: 'outlined'
    },
    [WccStatus.SENT]: {
      spanColor: 'primary',
      spanVariant: 'outlined',
      buttonColor: 'primary',
      buttonVariant: 'outlined',
      icon: <DoneIcon />,
    },
    [WccStatus.UPLOADED]: {
      spanColor: 'warning',
      spanVariant: 'filled',
      buttonColor: 'warning',
      buttonVariant: 'contained',
    },
    [WccStatus.CONFIRMED]: {
      spanColor: 'success',
      spanVariant: 'outlined',
      buttonColor: 'success',
      buttonVariant: 'outlined',
    },
    [WccStatus.ORIGINAL]: {
      spanColor: 'success',
      spanVariant: 'filled',
      buttonColor: 'success',
      buttonVariant: 'contained',
    },
  };

type Props = {
  status: WccStatus,
  size?: 'small' | 'medium'
}

export const WccStatusSpan = ({ status, size = 'medium' }: Props) => {
  const params = WCC_STATUS_STYLE[status];
  return (
    <Chip
      variant={params.spanVariant}
      label={WCC_STATUSES_MAP[status]}
      size={size}
      icon={params.icon}
      color={params.spanColor}
    />
  );
};
