import { LegendItem, LegendList } from 'shared/ui/legend-list';
import { Box, Button } from '@mui/material';
import PriceSpan from 'shared/ui/price-span';
import { CardPaper } from 'shared/ui/card-paper';
import { InvoiceReportTotals } from 'entities/invoice-report/invoice-report-rows';
import { InvoiceReportRowDto } from '@sr/dto';
import { PaidProgress } from 'shared/ui/progress-bar';
import Typography from '@mui/material/Typography';
import {
  useInvoiceControl,
  useInvoiceClosingData,
  useSetInvoiceClosingPeriod,
  useSetManagerPercent,
} from 'entities/invoice-report/invoice-close/invoice-close.hook';
import { ErrorBanner } from 'shared/ui/error-banner';
import ManagerPercentageInput from 'entities/invoice-report/invoice-close/manager-percentage.input';
import { Period } from 'entities/period-picker/types';
import { useSnack } from 'shared/ui/snack';
import { SalaryPeriodPicker } from 'entities/salary/salary-period/salary-period-picker';
import useConfirmDialog from '../../../hooks/confirm-dialog.hook';

type Props = {
  totals: InvoiceReportTotals;
  rows: InvoiceReportRowDto[];
  invoiceId: number;
}

export const InvoiceCloseForm = ({ totals, rows, invoiceId }: Props) => {
  const { invoiceClosingData, isLoading, error } = useInvoiceClosingData(invoiceId);
  const { setPercentage, isLoading: isSettingPercentage } = useSetManagerPercent(invoiceId);
  const { setPeriod, isLoading: isSettingPeriod } = useSetInvoiceClosingPeriod(invoiceId);
  const { closeInvoice, openInvoice, isLoading: isInvoiceClosing } = useInvoiceControl();

  const closeConfirmDialog = useConfirmDialog();

  const { showError, showSuccess } = useSnack();
  const isClosed = invoiceClosingData.managerCommission !== null
    ;
  const managerCommission = invoiceClosingData.managerCommission
    ? invoiceClosingData.managerCommission // invoice is closed
    : totals.totalProfitByPurchases * invoiceClosingData.managerPercent / 100;

  if (error) {
    return (<ErrorBanner errorMessage={error} />);
  }

  const allRowsNumber = rows.reduce((acc, row) => acc + Number(!!row?.buyerRow), 0);
  const closedRowsNumber = rows.reduce((acc, row) => acc + Number(!!row?.sellerRow), 0);

  const handleManagerPercentageChange = async (newValue: number) => {
    await setPercentage(newValue)
      .then((res) => { showSuccess(`Ставка изменена на ${res.managerPercent}`) })
      .catch((e) => { showError('Не смог изменить ставку') });
  };

  const handlePeriodChange = async (newValue: Period) => {
    await setPeriod(newValue)
      .then(() => { showSuccess('Период изменен успешно') })
      .catch(() => { showError('Не смог изменить период') });
  };

  const handleInvoiceClose = async () => {
    if (closedRowsNumber !== allRowsNumber) {
      await closeConfirmDialog({ content: 'Не все строчки закрыты' });
    }

    await closeInvoice(invoiceId)
      .then(() => { showSuccess('Счет успешно закрыт') })
      .catch((e) => {
        showError('Не смог закрыть счет: ' + e);
      });
  };

  const handleInvoiceOpen = async () => {
    await openInvoice(invoiceId)
      .then(() => { showSuccess('Счет успешно открыт') })
      .catch((e) => {
        showError('Не смог открыть счет: ' + e);
      });
  };

  return (<>
    <CardPaper>
      <LegendList>
        <PaidProgress variant="determinate" value={closedRowsNumber / allRowsNumber * 100} />
        <SalaryPeriodPicker
          selectedPeriod={invoiceClosingData.period}
          onSelect={handlePeriodChange}
          readOnly={isLoading || isInvoiceClosing || isClosed || isSettingPeriod} />
        <LegendItem title="Заявки:" value={
          <Typography>{closedRowsNumber}/{allRowsNumber}</Typography>
        } />
        <LegendItem title="Ставка:" value={
          <ManagerPercentageInput initialValue={invoiceClosingData.managerPercent}
            onChange={handleManagerPercentageChange}
            readOnly={isLoading || isInvoiceClosing || isClosed || isSettingPercentage} />
        } />
        <LegendItem title="ЗП менеджера:" value={<PriceSpan size='small' price={managerCommission} />} />
      </LegendList>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button disabled={isInvoiceClosing} onClick={isClosed ? handleInvoiceOpen : handleInvoiceClose}>
          {isClosed ? 'Открыть' : 'Закрыть'}
        </Button>
      </Box>
    </CardPaper>
  </>);
};
