import { Dialog } from '../../../shared/ui/dialog';
import {
  useGetMailAttachments,
  useParsedEmailDetails,
} from '../../../features/parsed-emails/parsed-emails-actions/parsed-emails-actions.hook';
import { Stack, Box, Typography } from '@mui/material';
import { LinkToFile } from '../../../shared/ui/links/link-to-file';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { formatDateTime } from 'utils/date-format-helpers';
import EmailAddress from '../../../shared/ui/email-address';

type ViewEmailModalProps = {
  open: boolean;
  onClose: () => void;
  id: number
}

export const ViewEmailModal = ({ open, onClose, id }: ViewEmailModalProps) => {
  const { parsedEmailData } = useParsedEmailDetails(id);
  const attachmentIds = parsedEmailData?.attachmentIds.split(',').map(Number) || [];
  const { attachments } = useGetMailAttachments(attachmentIds, attachmentIds?.length <= 0);

  if (!parsedEmailData) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <Dialog.Title>{parsedEmailData.subject}</Dialog.Title>
      <Box p = {3} mb = {4}>
        <Stack direction="column" sx = {{ backgroundColor: '#f4f4f4', borderRadius: '10px' }} p = {2}>
          <Stack direction = "row" display = "flex" spacing = {2}>
            <Box display = "flex" gap = {0.5}>От: <EmailAddress email={parsedEmailData.from}/></Box>
            {parsedEmailData.type === 'OUT' ? <ArrowCircleDownIcon color = "success"/> : <ArrowCircleUpIcon color = "warning"/> }
            <Box display = "flex" gap = {0.5}>К: <EmailAddress email={parsedEmailData.to}/></Box>
          </Stack>
          <Typography variant = "caption">Дата получения {formatDateTime(parsedEmailData.date)}</Typography>
        </Stack>
        <Stack mt = {2}>
          <div dangerouslySetInnerHTML= {{ __html: parsedEmailData.body }}/>
          {attachments && <Box display = "flex" flexDirection = "row" gap = {2} mt = {2} sx = {{ '& > a' : { display: 'flex' } }}>
            {attachments.map((file, index)=> (
              <LinkToFile key = {file.id}
                file={{ id: file.id, fileName: file.fileName, extension: file.extension }}
                icon={<FilePresentIcon/>}/>
            ))}
          </Box>}
        </Stack>
      </Box>
    </Dialog>
  );
};
