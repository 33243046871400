import { Button, MenuItem, Stack } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { PURCHASE_STATUSES, PurchaseDto } from '@sr/dto';
import { FormData, fromDto, validate } from './purchase.form';
import FilePickerField from 'shared/ui/formik-fields/file-picker';

type Props = {
    purchase: PurchaseDto;
    onSubmit: (formData: FormData) => Promise<void>;
};

export const PurchaseForm = ({ purchase, onSubmit }: Props) => {

  const initial = fromDto(purchase);

  return (
    <Formik
      initialValues={initial}
      enableReinitialize
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ submitForm }: any) => (
        <Form>
          <Stack direction="column" spacing={2}>
            <Field
              label='Статус'
              component={TextField}
              select
              name='status'
            >
              {PURCHASE_STATUSES.map((s) => (
                <MenuItem
                  key={s.id}
                  value={s.id}
                >
                  {s.label}
                </MenuItem>
              ))}
            </Field>
            <Field
              name='invoiceScan'
              label='Счет на оплату'
              component={FilePickerField}
            />
            <Field
              name='bankDetailsScan'
              label='Скан реквизитов'
              component={FilePickerField}
            />

            <Field
              name='wccScan'
              label='Скан акта поставщика'
              component={FilePickerField}
            />

            <Field
              name='promoScan'
              label='Подписанный макет'
              component={FilePickerField}
              multiple
            />
            <Field
              name='photoProof'
              label='Фотоотчет'
              component={FilePickerField}
              multiple
            />
            <Button onClick={submitForm} variant="contained" sx={{ ml: 'auto', mr: 1 }}>Сохранить</Button>
          </Stack>


        </Form>
      )
      }
    </Formik>);
};
