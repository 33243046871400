
export enum WccStatus {
  READY = "ready",
  SENT = "sent",
  UPLOADED = "uploaded",
  CONFIRMED = "confirmed",
  ORIGINAL = "original",
};

export const WCC_STATUSES_MAP  = {
  [WccStatus.READY] : 'Готов',
  [WccStatus.SENT]: 'Отправлен',
  [WccStatus.UPLOADED]: 'Загружен скан',
  [WccStatus.CONFIRMED]: 'Подтвержден',
  [WccStatus.ORIGINAL]: 'Оригинал',
}
