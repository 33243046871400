import { Typography } from '@mui/material';
import { INVOICE_TYPE_SUPPLIER } from '@sr/dto';
import { LegendItem, LegendList } from 'shared/ui/legend-list';
import { useInvoiceDetails } from 'entities/invoice/invoice.hook';
import { CardPaper } from 'shared/ui/card-paper';
import { InvoiceAppendix } from 'features/invoice/invoice-appendix/invoice-appendix';
import { InvoiceContract } from 'entities/invoice/ui/invoice-contract';
import { WccButton } from 'entities/wcc/wcc-button';


export const InvoiceDocuments = ({ invoiceId }: { invoiceId: number }) => {
  const { invoice } = useInvoiceDetails(invoiceId);

  if (!invoice)
    return null;
  if (invoice.type === INVOICE_TYPE_SUPPLIER)
    return null;

  return (
    <CardPaper sx={{ width: 400 }}>
      <Typography variant='h6'>Документы</Typography>
      <LegendList>
        {invoice.contract && <LegendItem title="Договор:" value={<InvoiceContract contract={invoice.contract} />}></LegendItem>}
        <LegendItem title="Приложение:" value={<InvoiceAppendix invoiceId={invoice.id} contractId={invoice.contract.id} />}></LegendItem>
        <LegendItem title="Акт выполненных работ:" value={<WccButton invoiceId={invoice.id} initialWcc={null}/>}></LegendItem>
      </LegendList>
    </CardPaper >
  );
};


