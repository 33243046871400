import RadioIcon from '@mui/icons-material/Radio';
import ArticleIcon from '@mui/icons-material/Article';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import { Box, Tab, Tabs } from '@mui/material';
import { Heading } from 'shared/ui/list-page-heading';
import { useLocation, resolvePath, Outlet, Route, Routes, Link } from 'react-router-dom';

import StorageIcon from '@mui/icons-material/Storage';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountingTab from 'components/Settings/accounting-tab';
import { useTitleHook } from 'hooks/page-title.hook';
import { ContractTemplatesTab } from 'components/Settings/contract-templates-tab';
import MediaTypesList from './media-types-list';
import { OurLegalEntitiesList } from 'entities/legal-entities/our-legal-entities/our-legal-entities-list';
import OwnerShipList from './form-of-ownership-list';
import PeopleIcon from '@mui/icons-material/People';
import { UsersRolesList } from '../../entities/user/user-roles/user-roles';
import EmailParserList from './email-parser-list';

const tabInfos = {
  'home': {
    label: 'Общее',
    icon: <StorageIcon />
  },
  'accounting': {
    label: 'Расчетные счета',
    icon: <AccountBalanceIcon />
  },
  'contract-templates': {
    label: 'Шаблоны договоров',
    icon: <ArticleIcon />
  },
  'media-types': {
    label: 'Настройки СМИ',
    icon: <RadioIcon />
  },
  'our-legal-entities': {
    label: 'Наши юр. лица',
    icon: <AccountBalanceIcon />
  },
  'form-of-ownership': {
    label: 'Формы собственности',
    icon: <OtherHousesIcon />
  },
  'email-parser': {
    label: 'Почта',
    icon: < MarkAsUnreadIcon />
  },
  'user-roles': {
    label: 'Роли пользователей',
    icon: <PeopleIcon />
  },
};
const tabs = Object.entries(tabInfos);

const SettingsPage = () => {
  const title = 'Настройки';
  useTitleHook(title);
  return (
    <>
      <Heading title={title} />

      <SettingsTabs />

      <Routes>
        <Route element={<Outlet />}>
          <Route path='home' element={<>To Do</>} />
          <Route path='accounting' element={<AccountingTab />} />
          <Route path='contract-templates' element={<ContractTemplatesTab />} />
          <Route path='media-types' element={<MediaTypesList />} />
          <Route path='our-legal-entities' element={<OurLegalEntitiesList />} />
          <Route path='form-of-ownership' element={<OwnerShipList />} />
          <Route path='email-parser' element={<EmailParserList />} />
          <Route path='user-roles' element={<UsersRolesList />} />
        </Route>
      </Routes>

    </>
  );

};


const SettingsTabs = () => {
  const location = useLocation();
  const pathSplit = location.pathname.split('/');
  pathSplit.pop();
  const root = pathSplit.join('/');

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
      <Tabs value={location.pathname}>
        {tabs.map(([route, info], i) => {
          const value = resolvePath(route, root).pathname;
          return <Tab
            key={i}
            label={info.label}
            value={value}
            iconPosition="start"
            icon={info.icon}
            to={route}
            component={Link} />;
        })}
      </Tabs>
    </Box>
  );
};


export default SettingsPage;
