import { Box, Checkbox, Collapse, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { GridLayout, HeadCellsCollection } from 'shared/ui/grid-layout';
import { NotificationsConfig } from 'shared/ui/snack';
import {
  BA_TYPES,
  BankAccountDto,
  InvoiceDto,
  IPagingData,
  PayOrderDto,
  PayOrderGroup,
  PayOrderRowDto,
  PayOrderStatus,
  PO_GROUPS,
  POS_STATUSES,
} from '@sr/dto';
import { formatDate } from 'utils/date-format-helpers';
import PriceSpan from 'shared/ui/price-span';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { PayordersFilter } from 'features/payorders/payorders-filter';
import React, { BaseSyntheticEvent, MouseEvent, useState } from 'react';
import { InvoiceStatusSpan } from '../../entities/invoice/status-span/status-span';
import { LinkToPayOrder } from 'shared/ui/links/link-to-payorder';


const columns = [
  'Номер ПП',
  'Дата',
  'Тип',
  'Группа',
  'Статус',
  'Сумма',
  'Счет',
  'Назначение платежа',
];

const invoiceColumns = [
  'Номер счета',
  'Дата выставления счета',
  'Юрлицо плательщика',
  'Юрлицо получателя',
  'Статус счета',
];

export const notificationsConfig: NotificationsConfig = {
  itemTitleForm1: 'платежное поручение',
  itemTitleForm2: 'платежного поручения',
  gender: 'neutral',
};

type FilterableList = (
  | { filterString: string, onFilter: (filterString: string) => void, }
  | { filterString?: undefined, onFilter?: undefined }
  )

type Props = {
  collection: IRemotePagedCollection<PayOrderDto>,
  paging: IPagingData,
  onChangePaging?: (paging: IPagingData) => void,
} & (
  | { onRowSelect: SelectHandler, selectedRows: number[] }
  | { onRowSelect?: undefined, selectedRows?: undefined }
  ) & EditableRowProps & FilterableList;

type SelectHandler = (rowId: number, value: boolean) => void

export const PayOrdersList = ({
  collection,
  paging,
  onRowSelect,
  selectedRows,
  onChangePaging,
  filterString,
  onFilter,
  onEditClick,
  onDeleteClick,
}: Props) => {
  const gridColumns: HeadCellsCollection = onRowSelect
    ? [{
      label: <SelectOneOrAllCell
        onRowSelect={onRowSelect}
        isSelected={!!collection.collection.items.length && collection.collection.items.length === selectedRows?.length}
        ids={collection.collection.items.map(item => item.id)} />,
    },
    ...columns]
    : columns;

  if (onEditClick && !gridColumns.includes('Действия')) {
    gridColumns.push('Действия');
  }

  return (
    <>
      {filterString !== undefined && onFilter &&
        <Box my={1}>
          <PayordersFilter filterString={filterString} onFilter={onFilter} />
        </Box>
      }
      <GridLayout
        columns={gridColumns}
        items={collection.collection.items}
        isLoading={collection.isLoading}
        error={collection.error}
        pagingData={paging}
        onChangePaging={onChangePaging}
        totalItemsCount={collection.collection.totalItemsCount}
        itemRender={payOrder => {
          let selectableProps: SelectableRowProps = { onRowSelect: undefined, isSelected: undefined };
          if (onRowSelect && selectedRows) {
            selectableProps = { onRowSelect: onRowSelect, isSelected: !!selectedRows.find(id => id === payOrder.id) };
          }
          let editableProps: EditableRowProps = { onDeleteClick: undefined, onEditClick: undefined };
          if (onEditClick && onDeleteClick) {
            editableProps = { onEditClick: onEditClick, onDeleteClick: onDeleteClick };
          }
          return <Row
            {...selectableProps}
            {...editableProps}
            key={payOrder.id}
            payOrder={payOrder}
            colSpan={gridColumns.length}
          />;
        }}
        noItemsText="Список платежных поручений пуст" />
    </>
  );
};

type RowProps = {
  payOrder: PayOrderDto,
  onRowSelect?: SelectHandler;
  colSpan: number;
} & SelectableRowProps & EditableRowProps;

type SelectableRowProps = (
  | { onRowSelect: SelectHandler; isSelected: boolean; }
  | { onRowSelect: undefined; isSelected: undefined; }
  )

type EditableRowProps = (
  | { onEditClick: (payOrder: PayOrderDto, dto?: any) => void, onDeleteClick: (payOrder: PayOrderDto) => void, }
  | { onEditClick?: undefined, onDeleteClick?: undefined, }
  );

const Row = ({ payOrder, onEditClick, onDeleteClick, onRowSelect, isSelected, colSpan }: RowProps) => {
  const [open, setOpen] = useState(false);

  const handleRowClick = () => {
    setOpen(prev => !prev);
  };

  const handleRowDelete = (e: MouseEvent) => {
    e.stopPropagation();
    if (onDeleteClick) {
      onDeleteClick(payOrder);
    }
  };

  const handleRowEdit = (e: MouseEvent) => {
    e.stopPropagation();
    if (onEditClick) {
      onEditClick(payOrder);
    }
  };

  return (
    <>
      <TableRow sx={{ cursor: 'pointer' }} onClick={handleRowClick}>
        {onRowSelect &&
          <TableCell>
            <SelectOneOrAllCell ids={[payOrder.id]} isSelected={isSelected} onRowSelect={onRowSelect} />
          </TableCell>
        }
        <TableCell>
          <LinkToPayOrder payorder={payOrder}>{payOrder.documentNumber}</LinkToPayOrder>
        </TableCell>
        <TableCell>
          {formatDate(payOrder.paymentDate)}
        </TableCell>
        <TableCell>
          {BA_TYPES[payOrder.bankAccount.type]}
        </TableCell>
        <TableCell>
          {PO_GROUPS[payOrder.group as PayOrderGroup]}
        </TableCell>
        <TableCell>
          {POS_STATUSES[payOrder.status as PayOrderStatus]}
        </TableCell>
        <TableCell>
          <PriceSpan price={payOrder.rows.reduce((acc, row) => acc + row.amount, 0)} size="small" />
        </TableCell>
        <TableCell>
          <BankAccountSpan bankAccount={payOrder.bankAccount} />
        </TableCell>
        <TableCell>
          <InvoicePaymentDescription payorder={payOrder} />
        </TableCell>
        {onEditClick &&
          <TableCell align="right" width={100}>
            <IconButton edge="end" onClick={handleRowEdit}>
                <EditIcon />
              </IconButton>
            <IconButton edge="end" onClick={handleRowDelete}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        }
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box marginY={1}>
              <GridLayout<PayOrderRowDto>
                columns={invoiceColumns}
                items={payOrder.rows}
                isLoading={false}
                itemRender={payOrderRow => (payOrderRow.invoiceId &&
                  <InvoiceSubRow key={payOrderRow.invoiceId} invoice={payOrderRow.invoice} />)}
                noItemsText="Нет привязанных счетов"
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};


export default PayOrdersList;


const InvoiceSubRow = ({ invoice }: { invoice: InvoiceDto }) => {
  return (<TableRow>
    <TableCell>
      <LinkToInvoice invoice={invoice} />
    </TableCell>
    <TableCell>
      <LinkToInvoice invoice={invoice}>
        {formatDate(invoice.startDate)}
      </LinkToInvoice>
    </TableCell>
    <TableCell><LinkToLegalEntity legalEntity={invoice.seller} /></TableCell>
    <TableCell><LinkToLegalEntity legalEntity={invoice.buyer} /></TableCell>
    <TableCell><InvoiceStatusSpan status={invoice.status} size="small" /></TableCell>
  </TableRow>);
};

const InvoicePaymentDescription = ({ payorder }: { payorder: PayOrderDto; }) => {
  return (
    <Typography component="span" variant="inherit" fontStyle="italic">
      {payorder.description}
    </Typography>
  );
};


const BankAccountSpan = ({ bankAccount }: { bankAccount: BankAccountDto; }) => (
  <Typography variant="inherit" color={bankAccount.isActive ? 'inherit' : 'grey.500'}>
    {bankAccount.title}
  </Typography>
);

const SelectOneOrAllCell = ({ onRowSelect, isSelected, ids }: {
  onRowSelect: SelectHandler,
  isSelected: boolean,
  ids: number[]
}) => {
  const handleCheckChange = (_: BaseSyntheticEvent, value: boolean) => {
    ids.forEach((id) => {
      onRowSelect(id, value);
    });
  };
  return (
    <Checkbox onChange={handleCheckChange} checked={isSelected} />
  );
};
