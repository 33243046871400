import { styled, Tooltip, Typography } from "@mui/material"
import { getProfitabilityRangeColor } from "./lib"

const Badge = styled(Typography)<{ valueInPercent: number }>(({ theme, valueInPercent }) => ({
  backgroundColor: getProfitabilityRangeColor(valueInPercent, theme),
  color: valueInPercent >= 0 ? theme.palette.success.contrastText : theme.palette.error.contrastText,

  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  display: 'inline-block',
  textAlign: 'center',
  fontSize: '0.75rem',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',
}))

type Props = {
  valueInPercent: number
}
export const ProfitabilityBadge = ({ valueInPercent }: Props) => {
  return (
    <Tooltip title="Рентабельность" placement="top">
      <Badge valueInPercent={valueInPercent}>{valueInPercent.toFixed(0)}%</Badge>
    </Tooltip>
  )
}