import { Step, StepLabel, Stepper } from '@mui/material';
import { WccStatus } from '@sr/dto';

export const WccStatusStepper = ({ status }: { status: WccStatus }) => {

  const steps = Object.values(WccStatus);

  return (<Stepper activeStep={steps.findIndex((step)=>step === status)}>
    {steps.map((label) => (
      <Step key={label}>
        <StepLabel>{label}</StepLabel>
      </Step>
    ))}
  </Stepper>);
};
