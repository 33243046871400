import { InvoicesList } from 'entities/invoice/list/invoices-list';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { InvoiceInfoDto, } from '@sr/dto';
import { useClientInvoices } from 'entities/client-invoices-list/lib/client-invoices-list.hook';
import { Heading } from 'shared/ui/list-page-heading';
import { useTitleHook } from 'hooks/page-title.hook';
import { usePagingParams } from 'shared/hooks/paging-params';
import { useSearchParams } from 'react-router-dom';
import { invoiceCanceledStatusApi } from 'features/invoice/invoice-cancel-status';
import { useSnack } from 'shared/ui/snack';

const title = 'Клиентские счета';

const ClientInvoicesListPage = () => {
  useTitleHook(title);
  const [paging, setPaging] = usePagingParams();
  const [searchParams] = useSearchParams();

  const { collection } = useClientInvoices(paging, searchParams.toString());

  const showConfirmDialog = useConfirmDialog();

  const [changeCancelStatus] = invoiceCanceledStatusApi.useUpdateInvoiceCanceledStatusMutation();

  const { showSuccess, showError } = useSnack();

  const handleConfirmCancel = (invoice: InvoiceInfoDto) => {
    if (!invoice)
      return;

    changeCancelStatus({ invoiceId: invoice.id })
      .unwrap()
      .then(() => showSuccess('Счет отменён'))
      .catch(e => showError('Ошибка изменения статуса счета', e));
  };

  const handleCancelInvoice = (invoice: InvoiceInfoDto) => {
    showConfirmDialog({
      content: 'Отменить счет?'
    })
      .then(() => handleConfirmCancel(invoice));
  };

  return (
    <>
      <Heading
        sx={{ mb: 2 }}
        title={title} />

      <InvoicesList
        collection={collection}
        paging={paging}
        onChangePaging={setPaging}
        onCancel={handleCancelInvoice}
      />
    </>
  );
};

export default ClientInvoicesListPage;
