import { Box, Stack, Typography } from '@mui/material';
import { useInvoiceDetails } from 'entities/invoice/invoice.hook';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { useTitleHook } from 'hooks/page-title.hook';
import { RelatedPayorders } from 'entities/invoice-report/related-payorders';
import { ProfitCard } from 'entities/invoice-report/profit-card';
import { SellerCard } from 'entities/invoice-report/report-seller-card';
import { RelatedInvoices } from 'entities/invoice-report/related-invoices';
import { PageHeader } from 'shared/ui/details-page-header';
import { useIdParam } from 'utils/url-paramters.hook';
import { useInvoiceTotals } from 'entities/invoice/totals';
import { InvoiceReportRows } from 'entities/invoice-report/invoice-report-rows';
import { useInvoiceReportRows } from 'entities/invoice-report/invoice-report.hook';
import { INVOICE_TYPE_CLIENT, PayOrderDto } from '@sr/dto';
import { calculateInvoiceReportTotals } from 'entities/invoice-report/lib';
import { InvoiceCloseForm } from '../../features/invoice/invoice-close/invoice-close.form';

const InvoiceReportPage = () => {
  const invoiceId = useIdParam('invoiceId');
  const { invoice } = useInvoiceDetails(invoiceId);
  const { totals: invoiceTotals } = useInvoiceTotals(invoiceId);
  const { report, error, isLoading: supplierPaymerntLoading } = useInvoiceReportRows(invoiceId);

  useTitleHook(invoice && `Отчет по счету ${invoice.invoiceNumber} для ${invoice.buyerCompany.name}`);

  const rows = report?.rows || [];
  const supplierPayments = report?.rows.reduce<PayOrderDto[]>((acc, row) => {
    if (row.sellerPayments)
      return acc.concat(...row.sellerPayments);
    return acc;
  }, []);


  if (!invoice)
    return <LoadingBanner />;

  const totals = calculateInvoiceReportTotals(rows);

  return (
    <>
      <PageHeader
        title={
          <>
            <Typography variant='h5' component="span">Отчет по счету </Typography>
            <LinkToInvoice invoice={invoice}>
              <Typography variant='h5' gutterBottom component="span">{invoice?.invoiceNumber}</Typography>
            </LinkToInvoice>
          </>}
        actions={undefined} />


      <Stack direction="row" spacing={1}>
        <Box sx={{ flexBasis: '50%', flexGrow: 1, minWidth: '350px' }}>
          <SellerCard
            buyer={invoice.buyerCompany}
            buyerLegalEntity={invoice.buyer}
            sellerLegalEntity={invoice.seller}
            status={invoice.status} />
        </Box>
        {invoiceTotals &&
          <Box sx={{ flexBasis: '25%', flexGrow: 1, minWidth: '250px' }}>
            <ProfitCard profits={totals} showProfits={invoice.type === INVOICE_TYPE_CLIENT} />
          </Box>
        }
        <Box sx={{ flexBasis: '25%', flexGrow: 1, minWidth: '250px' }}>
          <InvoiceCloseForm totals={totals} rows={rows} invoiceId={invoiceId} />
        </Box>
      </Stack>
      <Box marginTop={2}>
        <Typography variant='h6' gutterBottom>Услуги</Typography>
        <InvoiceReportRows invoice={invoice} rows={rows} totals={totals} />
      </Box>
      <Box marginTop={2}>
        <Typography variant='h6' gutterBottom>Платежи по счету</Typography>
        <RelatedPayorders
          payOrders={{
            error: error,
            isLoading: false,
            items: report?.clientPayments.flatMap(p => p) ?? [],
            reload: () => { }
          }}
          referenceInvoiceId={invoiceId}
        />
      </Box>
      <Box marginTop={2}>
        <Typography variant='h6' gutterBottom>Платежи по связным счетам</Typography>
        {supplierPayments &&
          <RelatedPayorders
            payOrders={{
              error: error, isLoading: supplierPaymerntLoading,
              items: supplierPayments,
              reload: () => { }
            }}
            referenceInvoiceId={invoiceId} />
        }
      </Box>
      <Box marginTop={2}>
        <Typography variant='h6' gutterBottom>Связанные счета</Typography>
        <RelatedInvoices invoiceId={invoiceId} />
      </Box>
    </>
  );
};


export default InvoiceReportPage;
