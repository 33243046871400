import { MouseEvent } from 'react';
import MenuParams from 'shared/ui/menu-params';
import { IconButton, TableCell, TableRow } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { InvoiceInfoDto, IPagingData } from '@sr/dto';
import { GridLayout } from 'shared/ui/grid-layout';
import { RowMenu, useMenu } from 'shared/ui/row-menu';
import { formatDate } from 'utils/date-format-helpers';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import PriceSpan from 'shared/ui/price-span';
import { InvoiceStatusSpan } from '../status-span/status-span';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import ManagerSpan from 'shared/ui/manager-span';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { InvoiceFilter } from 'features/invoice/invoice-filter/invoice-filter';
import { WccButton } from 'entities/wcc/wcc-button';

const columns = ['Дата создания', 'Номер', 'Клиент', 'Наше юрлицо', 'Клиентское юрлицо', 'Статус', 'Менеджер', 'Сумма', 'Сумма со скидкой', 'Акт',''];

type Props = {
  collection: IRemotePagedCollection<InvoiceInfoDto>,
  paging: IPagingData,
  onChangePaging: (paging: IPagingData) => void,
  onCancel: (invoice: InvoiceInfoDto) => void,
};

export const InvoicesList = ({ collection, onCancel, paging, onChangePaging }: Props) => {
  const { openMenu, menuData } = useMenu<InvoiceInfoDto>({ cancelHandler: onCancel });
  return (
    <>
      <InvoiceFilter />
      <GridLayout
        columns={columns}
        items={collection.collection.items}
        isLoading={collection.isLoading}
        error={collection.error}
        noItemsText="Список счетов пуст"
        pagingData={paging}
        onChangePaging={onChangePaging}
        totalItemsCount={collection.collection.totalItemsCount}
        itemRender={(i) =>
          <InvoiceListRow
            key={i.id}
            invoice={i}
            onMenuClick={openMenu} />}
      />

      <RowMenu menuData={menuData} />
    </>);
};


type RowProps = {
  invoice: InvoiceInfoDto,
  onMenuClick: (menuParams: MenuParams<InvoiceInfoDto>) => void;
};

const InvoiceListRow = ({ invoice, onMenuClick }: RowProps) => {

  const priceTotal = invoice.totals.priceTotal;
  const discountPriceTotal = invoice.totals.discountPriceTotal;

  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    onMenuClick({ anchor: e.currentTarget, target: invoice });
  };

  return (
    <>
      <TableRow
        key={invoice.id}
        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>
          <LinkToInvoice invoice={invoice}>
            {formatDate(invoice.startDate)}
          </LinkToInvoice>
        </TableCell>
        <TableCell>
          <LinkToInvoice invoice={invoice} />
        </TableCell>
        <TableCell><LinkToCompany company={invoice.buyerCompany} /></TableCell>
        <TableCell><LinkToLegalEntity legalEntity={invoice.seller} /></TableCell>
        <TableCell><LinkToLegalEntity legalEntity={invoice.buyer} /></TableCell>
        <TableCell><InvoiceStatusSpan status={invoice.status} size="small" /></TableCell>
        <TableCell><ManagerSpan person={invoice.manager} /></TableCell>
        <TableCell><PriceSpan size="small" price={priceTotal} /></TableCell>
        <TableCell><PriceSpan size="small" price={discountPriceTotal} /></TableCell>
        <TableCell><WccButton invoiceId={invoice.id} initialWcc={invoice.wcc}/></TableCell>
        <TableCell align="right">
          <IconButton edge="end" onClick={handleMenuClick}><MoreVertIcon /></IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};



