import { InvoiceReportRowDto } from '@sr/dto';
import { InvoiceReportTotals } from './invoice-report-rows';
import { Theme } from '@mui/material';

/**
 * Подсчитывает итоги по отчету по счету. Суммы строк клиентских и поставщиков, частичные оплаты и т.д.
 * @param rows
 * @returns
 */
export const calculateInvoiceReportTotals = (
  rows: InvoiceReportRowDto[]
): InvoiceReportTotals => ({
  buyerRowsTotal: rows.reduce(
    (acc, row) => acc + row.buyerRow.priceDiscount,
    0
  ),
  sellerrRowsTotal: rows.reduce(
    (acc, row) => acc + (row.sellerRow?.priceDiscount ?? 0),
    0
  ),

  buyerPartialPaidTotal: rows.reduce(
    (acc, row) => acc + (row.buyerRow.paidAmount ?? 0),
    0
  ),
  sellerPartialPaidTotal: rows.reduce(
    (acc, row) => acc + (row.sellerRow?.paidAmount ?? 0),
    0
  ),

  totalProfitByPayments: rows.reduce(
    (acc, row) => acc + (row.profitByPayments ?? 0),
    0
  ),
  totalProfitByPurchases: rows.reduce(
    (acc, row) => acc + (row.profitByPurchases ?? 0),
    0
  ),
});

export const getProfitabilityRangeColor = (
  profitabilityInPercent: number,
  theme: Theme
) =>
  profitabilityInPercent <= 0
    ? theme.palette.error.main
    : profitabilityInPercent <= 30
    ? theme.palette.warning.main
    : profitabilityInPercent >= 30
    ? theme.palette.success.main
    : theme.palette.info.main;
