const DATE_SPLITTER = "."

export const BOOLEAN_LIKE_STRINGS = ["true", "false"]
export const DATE_DTO_REGEX = new RegExp(`^([0-2][1-9]|10|20|30|31)${DATE_SPLITTER}(0[1-9]|1[012])${DATE_SPLITTER}(19\\d{2}|20\\d{2})$`);
// e.g. "11.03.2024"

export const queryStringToDate = (dateString: string): Date =>{
  const parts = dateString.split(DATE_SPLITTER);
  const year = Number(parts[2]);
  const month = Number(parts[1]);
  const day = Number(parts[0]);
  return new Date(year, month - 1, day);
}

export const validateQueryStringIsCommaSeparatedIntArray = (input: string) => {
  if (!input.includes(',') && isNaN(Number(input))) {
    throw new Error('Expected value like "1,2,3" or "2"')
  }
  for (const num in input.split(',')){
    if (!num || isNaN(Number(num))){
      throw new Error('Expected value like "1,2,3" or "2"')
    }
  }
}

export const queryStringToNumbersArray = (input: string) => {
  if(!input) return [];
  return input.split(',').map(Number)
}

export const validateQueryStringDate = (dateSting: string): void => {
  if (!DATE_DTO_REGEX.test(dateSting)) throw new Error(`Must be date-like string: "31.12.2024", got: "${dateSting}"`);
}

export const queryStringToBool = (boolString: string): boolean => {
  return boolString === "true"
}

export const validateStringBool = (boolString: string): void => {
  if (!BOOLEAN_LIKE_STRINGS.includes(String(boolString))) throw new Error(`"${boolString}" cannot be converted to boolean`)
}

export const date2QueryString = (date: Date): string => {
  return date.toLocaleDateString('ru-RU') // e.g. "11.03.2024"
}
