import { AlertColor } from '@mui/material';
import { createContext, useCallback, useContext } from 'react';
import { extractNestJsErrorMessage, isErrorWithMessage } from 'shared/api/rtk-query';

type GenderForm = 'male' | 'female' | 'neutral' | 'plural';

export type NotificationsConfig = {
  /**
   * именительный падеж: компания, фирма, счет
   */
  itemTitleForm1: string;
  /**
   * родительный падеж, кого?, чего?: компании, фирмы, счета
   */
  itemTitleForm2: string;
  gender: GenderForm;
};

const genderLookup: { [key in GenderForm]: { add: string; edit: string; delete: string } } = {
  male: {
    add: 'добавлен',
    edit: 'обновлен',
    delete: 'удален',
  },
  female: {
    add: 'добавлена',
    edit: 'обновлена',
    delete: 'удалена',
  },
  neutral: {
    add: 'добавлено',
    edit: 'обновлено',
    delete: 'удалено',
  },
  plural: {
    add: 'добавлены',
    edit: 'обновлены',
    delete: 'удалены',
  },
};

export type SnackActions = {
  showSuccess: (msg: string) => void;
  showError: (msg: string, e?: unknown) => void;
  showWarning: (msg: string) => void;
  showSuccessAdd: () => void;
  showFailedAdd: (e: Error) => void;
  showSuccessUpdate: () => void;
  showFailedUpdate: (e: Error) => void;
  showSuccessDelete: () => void;
  showFailedDelete: (e: Error) => void;
};

const FN_NOT_IMPLEMENTED = () => {
  throw new Error('Function not implemented.');
};

const defaultValue: SnackActions = {
  showError: FN_NOT_IMPLEMENTED,
  showWarning: FN_NOT_IMPLEMENTED,
  showSuccess: FN_NOT_IMPLEMENTED,
  showSuccessAdd: FN_NOT_IMPLEMENTED,
  showFailedAdd: FN_NOT_IMPLEMENTED,
  showSuccessUpdate: FN_NOT_IMPLEMENTED,
  showFailedUpdate: FN_NOT_IMPLEMENTED,
  showSuccessDelete: FN_NOT_IMPLEMENTED,
  showFailedDelete: FN_NOT_IMPLEMENTED,
};

export type SnackInfo = {
  msg: string;
  severity: AlertColor;
};

/**
 * Возвращает error.message, если доступен или пытается достать описание ошибки из ответа сервера
 */
const extractErrorMessage = (e: Error): string => {
  if (!!e && isErrorWithMessage(e)) {
    return e.message;
  }
  try {
    return extractNestJsErrorMessage(e);
  } catch (e) {
    return 'Неизвестная ошибка';
  }
};

export const SnackContext = createContext<(_: SnackInfo) => void>(FN_NOT_IMPLEMENTED);

export const useSnack = (notifications?: NotificationsConfig) => {
  const setInfo = useContext(SnackContext);

  const actions = defaultValue;
  actions.showSuccess = useCallback((msg: string) => setInfo({ msg: msg, severity: 'success' }), [setInfo]);
  actions.showError = useCallback(
    (msg: string, error?: unknown) => {
      setInfo({
        msg: error ? `${msg}: ${extractNestJsErrorMessage(error)}` : msg,
        severity: 'error',
      });
    },
    [setInfo]
  );
  actions.showWarning = useCallback(
    (msg: string) => {
      setInfo({
        msg: msg,
        severity: 'warning',
      });
    },
    [setInfo]
  );

  if (notifications) {
    const { itemTitleForm1, itemTitleForm2, gender } = notifications;
    const capitalizedForm1 = itemTitleForm1.charAt(0).toUpperCase() + itemTitleForm1.slice(1);

    actions.showSuccessAdd = () => actions.showSuccess(`${capitalizedForm1} успешно ${genderLookup[gender].add}`);
    actions.showFailedAdd = (e: Error) => {
      actions.showError(`Ошибка добавления ${itemTitleForm2}: ${extractErrorMessage(e)}`);
    };

    actions.showSuccessUpdate = () => actions.showSuccess(`${capitalizedForm1} успешно ${genderLookup[gender].edit}`);
    actions.showFailedUpdate = (e: Error) => {
      actions.showError(`Ошибка обновления ${itemTitleForm2}: ${extractErrorMessage(e)}`);
    };

    actions.showSuccessDelete = () => actions.showSuccess(`${capitalizedForm1} успешно ${genderLookup[gender].delete}`);
    actions.showFailedDelete = (e: Error) => {
      actions.showError(`Ошибка удаления ${itemTitleForm2}: ${extractErrorMessage(e)}`);
    };
  }

  return actions;
};
