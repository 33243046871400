import { Box, Divider, MenuItem, Stack } from '@mui/material';
import { PayOrderGroup, PayOrderStatus, POS_STATUSES, PO_GROUPS, InvoiceType, getInvoiceTypeByPayOrderGroup } from '@sr/dto';
import { FormData } from './pay-order.form-tools';
import { Field, FormikProps, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { CashflowPicker } from 'shared/ui/formik-fields/cashflow-picker';
import { BankAccountPicker } from 'shared/ui/formik-fields//bank-account-picker';
import { PayOrderInvoiceRows } from './pay-order-rows';
import { ChangeEvent, useState, useEffect } from 'react';

export const PayOrderFormFields = (props: FormikProps<FormData> & { isNew: boolean; }) => {
  const { setFieldValue, values } = useFormikContext<FormData>();
  const { isNew } = props;
  const [invoiceTypeFilter, setInvoiceTypeFilter] = useState<InvoiceType | null>(null);

  useEffect(() => {
    setInvoiceTypeFilter(getInvoiceTypeByPayOrderGroup(values.group as PayOrderGroup));
  }, []);
  
  const clearRows = () => {
    setFieldValue('rows', []);
  };

  const onGroupChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    const invoiceType = getInvoiceTypeByPayOrderGroup(value as PayOrderGroup);
    setInvoiceTypeFilter(prev => {
      if(prev !== invoiceType && !(value && invoiceType === null)) clearRows();      
      return invoiceType;
    });
    setFieldValue('group', value);
  };
  
  return (
    <>
      <Stack direction="column" spacing={2}>
        <Box minWidth={400}>
          <Stack direction="column" spacing={2}  >
            <Stack direction="row" spacing={2} >
              <Field
                required
                name='paymentDate'
                component={DatePickerField}
                fullWidth
                label='Дата ПП'
              />
              <Field
                required
                name='documentNumber'
                component={TextField}
                label='Номер'
                fullWidth
              />
            </Stack>
            <Field
              required
              name='accountId'
              component={BankAccountPicker}
              label='Расчетный счет'
              fullWidth
            />
            <Field
              required
              name='description'
              component={TextField}
              label='Назначение платежа'
              fullWidth
              rows={2}
              multiline
            />
            <Field
              required
              name='group'
              component={TextField}
              select
              fullWidth
              label='Группа'
              onChange={onGroupChange}
            >
              {Object.keys(PO_GROUPS).map(key =>
                <MenuItem key={key} value={key}>
                  {PO_GROUPS[Number(key) as PayOrderGroup]}
                </MenuItem>)}
            </Field>
            <Field
              required
              name='cashflowId'
              component={CashflowPicker}
              fullWidth
              label='Статья движения денежных средств'
            />
            <Field
              name='status'
              component={TextField}
              select
              fullWidth
              label='Статус'
              disabled={isNew}
            >
              {Object.keys(POS_STATUSES).map(key =>
                <MenuItem key={key} value={key}>
                  {POS_STATUSES[Number(key) as PayOrderStatus]}
                </MenuItem>)}
            </Field>

          </Stack>
        </Box>

        <Divider flexItem />

        <Box minWidth={400}>
          <PayOrderInvoiceRows invoiceType={invoiceTypeFilter}/>
        </Box>
      </Stack>
    </>
  );
};
