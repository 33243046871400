import { Typography } from '@mui/material';
import { formatPrice } from 'utils/price-format-helpers';

export type PriceSpanProps = {
  price: number,
  size?: 'small' | 'regular',
  color?: string,

  // не отображать копейки, если они равны нулю
  showZeroKop?: boolean
  // если цена равна нулю, отображать тире
  zeroAsDash?: boolean
  // если цена приблизительная, отображать знак ~ перед ценой
  isApproximate?: boolean
  // подкрашивать цену в красный, если она меньше нуля
  coloring?: boolean
}
const PriceSpan = ({ price, size, color: overrideColor, showZeroKop, zeroAsDash, isApproximate, coloring }: PriceSpanProps) => {
  if (coloring && overrideColor) {
    console.warn('PriceSpan: color prop is ignored when coloring is true');
  }

  const color = price < 0 ? 'error' : undefined;

  if (zeroAsDash && price === 0) return (
    <Typography variant={size === 'small' ? 'body1' : 'h6'} fontWeight={500} component='span' color={color}>
      -
    </Typography>
  );

  const priceSplit = formatPrice(price).split('.');
  const kop = priceSplit[1];

  const formatter = new Intl.NumberFormat('ru-RU', {
    //currency: 'р',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const rubStr = formatter.format(price);
  const currencyColor = color ?? 'grey.500';
  return (
    <>
      <Typography variant={size === 'small' ? 'body1' : 'h6'} fontWeight={500} component='span' color={color}>
        {isApproximate && <Typography component='span' color={currencyColor}>~</Typography>}{rubStr}
      </Typography>

      <Typography variant="caption" component='span' color={currencyColor} >
        р.
      </Typography>

      {(showZeroKop === true || kop !== '00') &&
        <>
          <Typography variant="body2" component='span' color={color}>
            {kop}
          </Typography>
          {/* &nbsp; */}
          <Typography variant="caption" component='span' color={currencyColor}>
            к.
          </Typography>
        </>
      }

    </>
  );
};

export default PriceSpan;