import { UserDto } from './user.dto';
import { IsDate, IsEnum, IsNotEmpty, IsNumber, IsString, MaxLength, Min } from 'class-validator';
import { Type } from 'class-transformer';
import { PayOrderStatus } from './enums/payorder';

export class TimeSheetDataRowDto {
  user: UserDto | null;
  closedWorkingHours: number | null;
  payload: string;
}

export class TimeSheetDataDto {
  dates: number[];
  rows: TimeSheetDataRowDto[];
}

export enum SALARY_ENTRY_TYPES {
  SEEK_COMPENSATION = 'seek_compensation',
  VACATION_COMPENSATION = 'vacation_compensation',
  BONUS = 'bonus',
  COMMISSION = 'commission',
  OTHER_ADDITIONS = 'other_additions',
  BASE_SALARY = 'base_salary',

  PREPAYMENT = 'prepayment',
  PENALTY = 'penalty',
  TAXES = 'taxes',
  OTHER_DEDUCTIONS = 'other_deductions',
}

export const ADDITION_TYPES = [
  SALARY_ENTRY_TYPES.BONUS,
  SALARY_ENTRY_TYPES.SEEK_COMPENSATION,
  SALARY_ENTRY_TYPES.VACATION_COMPENSATION,
  SALARY_ENTRY_TYPES.COMMISSION,
  SALARY_ENTRY_TYPES.OTHER_ADDITIONS,
  SALARY_ENTRY_TYPES.BASE_SALARY,
];
export const DEDUCTION_TYPES = [
  SALARY_ENTRY_TYPES.PENALTY,
  SALARY_ENTRY_TYPES.OTHER_DEDUCTIONS,
  SALARY_ENTRY_TYPES.PREPAYMENT,
  SALARY_ENTRY_TYPES.TAXES,
];


export class CreateSalaryEntryDto {
  @IsNumber()
  @Min(0.01)
  sum: number;

  @IsString()
  @IsEnum(SALARY_ENTRY_TYPES)
  @IsNotEmpty()
  type: SALARY_ENTRY_TYPES;

  @IsString()
  @MaxLength(255)
  description: string;
}

export class SalaryEntryDto extends CreateSalaryEntryDto {
  id: number;
  year: number;
  month: number;
  user: UserDto;
  createdBy: UserDto;
  invoiceId?: number;

  @IsDate()
  @Type(() => Date)
  createdAt: Date;
}

export class CreatePrepaymentDto {
  year: number;
  month: number;
  sum: number;
  userId: number;
  status: PayOrderStatus;
  description: string;
  accountId: number;
  paymentDate: Date;
}

export class PrepaymentDto extends CreatePrepaymentDto {
  id: number;
}

export class UpdatePrepaymentDto extends PrepaymentDto {
}
