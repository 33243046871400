import { Box, IconButton, TableCell, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridLayout, HeadCellsCollection } from 'shared/ui/grid-layout';
import { IPagingData, WccDto } from '@sr/dto';
import { formatDate } from 'utils/date-format-helpers';
import PriceSpan from 'shared/ui/price-span';
import { LinkToWcc } from 'shared/ui/links/link-to-wcc';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import { formatPerson } from 'utils/person-format-helpers';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';


const columns: HeadCellsCollection = [
  'Номер акта',
  'Счет',
  'Наше юрлицо',
  'Клиент',
  'Дата',
  'Менеджер',
  { label: 'Сумма', align: 'right' },
  ''
];

type Props = {
  collection: IRemotePagedCollection<WccDto>;
  paging: IPagingData;
  onChangePaging: (paging: IPagingData) => void;
  onDelete: (wcc: WccDto) => void;
};

export const WccList = ({ collection, paging, onChangePaging, onDelete }: Props) => {
  return (
    <Box>
      {/* <Button
        variant='contained'
        sx={{ mb: 2 }}
        onClick={() => { }}
      >
        Добавить
      </Button> */}

      <GridLayout
        tableOptions={{ component: 'div' }}
        columns={columns}
        items={collection.collection.items}
        isLoading={collection.isLoading}
        pagingData={paging}
        onChangePaging={onChangePaging}
        totalItemsCount={collection.collection.totalItemsCount}
        error={collection.error}
        itemRender={wcc => <Row
          key={wcc.id}
          wcc={wcc}
          // onEditClick={() => handleOpenForEdit(wcc)}
          onDeleteClick={() => onDelete(wcc)}
        />}
        noItemsText="Список актов пуст" />
    </Box>
  );
};



type RowProps = {
  wcc: WccDto,
  // onEditClick: () => void,
  onDeleteClick: () => void;
};

const Row = ({ wcc, onDeleteClick }: RowProps) => {
  return (
    <TableRow>
      <TableCell >
        <LinkToWcc wcc={wcc} />
      </TableCell>
      <TableCell>
        <LinkToInvoice invoice={wcc.invoice} />
      </TableCell>
      <TableCell>
        <LinkToLegalEntity legalEntity={wcc.invoice.seller} />
      </TableCell>
      <TableCell>
        <Box>
          <LinkToCompany company={wcc.invoice.buyerCompany} />
        </Box>
        <Box>
          <LinkToLegalEntity legalEntity={wcc.invoice.buyer} />
        </Box>
      </TableCell>
      <TableCell>
        {formatDate(wcc.date)}
      </TableCell>
      <TableCell>
        {formatPerson(wcc.invoice.manager)}
      </TableCell>
      <TableCell align="right">
        <PriceSpan size='small' price={wcc.amount} />
      </TableCell>
      <TableCell align="right" width={100}>
        {/* <IconButton edge="end" onClick={onEditClick}>
          <EditIcon />
        </IconButton> */}
        <IconButton edge="end" onClick={onDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow >
  );
};




