import {
  FormData as PrepaymentFormData,
  formTools as prepaymentFormTools,
  notificationsConfig as prepaymentNotificationsConfig,
} from '../prepayment.form-tools';
import { PrepaymentFormFields } from './prepayment.form-fields';
import { FormDialog } from 'shared/ui/form-dialog';
import * as React from 'react';
import { useEffect } from 'react';
import { useCreateOrEditDialog } from 'hooks/create-or-edit-dialog.hook';
import { CreatePrepaymentDto, PrepaymentDto, UpdatePrepaymentDto } from '@sr/dto';
import { useActionCreators, useStateSelector } from 'shared/store';
import { useAddPrepayment, useUpdatePrepayment } from '../prepayment.hook';
import { prepaymentDialogActions } from './prepayment-dialog.slice';
import { Period } from 'entities/period-picker/types';

interface Props {
  prepayment: React.MutableRefObject<PrepaymentDto | null>;
  // будет установлен в форме по умолчанию. Если не задан - текущий период используется
  selectedPeriod?: Period;
}

export const PrepaymentDialogForm = ({ prepayment, selectedPeriod }: Props) => {
  const isDialogOpened = useStateSelector(state => state.ui.prepaymentDialog.isPrepaymentDialogOpened);
  const { closeDialog: closePrepaymentDialog } = useActionCreators(prepaymentDialogActions);

  const addPrepayment = useAddPrepayment();
  const updatePrepayment = useUpdatePrepayment();

  const {
    dialogProps,
    openToAdd,
    openToEdit,
    itemToEdit,
  } = useCreateOrEditDialog<PrepaymentDto & { id: number },
    CreatePrepaymentDto,
    UpdatePrepaymentDto,
    PrepaymentFormData>({
      formTools: prepaymentFormTools,
      addHandler: async (dto) => {
        await addPrepayment(dto);
      },
      updateHandler: async (dto) => {
        await updatePrepayment(dto);
      },
      notificationsConfig: prepaymentNotificationsConfig,
    });

  useEffect( () => {
    if (!isDialogOpened) {
      return;
    }
    if (prepayment && prepayment.current) {
      openToEdit(prepayment.current);
    } else {
      openToAdd();
    }
    closePrepaymentDialog();

  }, [ isDialogOpened, prepayment ]);


  if (selectedPeriod) {
    dialogProps.initialValues.period = selectedPeriod;
  }

  return (
    <FormDialog<PrepaymentFormData>
      {...dialogProps}
      renderTitle={!itemToEdit ? 'Создание аванса': 'Редактирование аванса'}
      renderForm={(props) => {
        return <PrepaymentFormFields {...props} isNew={!itemToEdit} />;
      }}
    />
  );
};
