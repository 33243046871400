import { Type } from "class-transformer";
import { IsString, IsNumber, IsEnum } from 'class-validator';
import { WccStatus} from "./enums/wcc-statuses";
import { InvoiceDto } from "./invoice.dto";


export class WccDto {
  @IsNumber()
  id: number;

  @IsString()
  wccNumber: string;

  date: string;

  status: WccStatus;

  @Type()
  invoice: InvoiceDto;

  amount: number;
}

export class UpdateWccDto {
  @IsNumber()
  id: number;

  @IsEnum(WccStatus)
  status: WccStatus;

  // @IsString()
  // wccNumber: string;

  @Type()
  date: Date;

  amount: number;
}


export class InvoiceWccDto {
  wcc: WccDto
}

export class CreateInvoiceWccDto {
  @IsNumber()
  invoiceId: number;
}





