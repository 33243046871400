import { PurchaseDto } from '@sr/dto';
import { fileFieldToDto, filesFieldToDto, FormDataFileField } from 'utils/form-tools';
import { UpdatePurchaseClientDto } from 'entities/purchase/purchase.hook';

export type FormData = {
  status: number | '',

  invoiceScan: FormDataFileField,
  bankDetailsScan: FormDataFileField,
  wccScan: FormDataFileField,
  promoScan: FormDataFileField[],
  photoProof: FormDataFileField[],
};

type ValidationData = Partial<{ [x in keyof FormData]: string }>;

export const validate = (values: FormData): ValidationData => {
  let validationResult: ValidationData = {};

  return validationResult;
};

export const toDto = (purchaseId: number, formData: FormData) => {
  return {
    id: Number(purchaseId),
    statusId: Number(formData.status),
    invoiceScan: fileFieldToDto(formData.invoiceScan),
    bankDetailsScan: fileFieldToDto(formData.bankDetailsScan),
    wccScan: fileFieldToDto(formData.wccScan),
    promoScan: filesFieldToDto(formData.promoScan),
    photoProof: filesFieldToDto(formData.photoProof),
  } satisfies UpdatePurchaseClientDto;
};

export function fromDto(dto: PurchaseDto): FormData {
  return  {
    status: dto.status.id,
    invoiceScan: dto.invoiceScan,
    bankDetailsScan: dto.bankDetailsScan,
    wccScan: dto.wccScan,
    photoProof: dto.photoProof,
    promoScan: dto.promoScan
  } satisfies FormData;
}
