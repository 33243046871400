import { plainToInstance, Type } from 'class-transformer';
import { IsIn, IsNotEmpty, IsNumber } from 'class-validator';
import { DownloadFileDto } from './download-file.dto';
import { InvoiceRowDto } from './invoice-row.dto';
import { InvoiceDto } from './invoice.dto';
import { UserDto } from './user.dto';
import { PURCHASE_STATUSES } from './enums/purchase-statuses';

export class PurchaseInfoDto {
  @IsNumber()
  id: number;

  invoiceScan: DownloadFileDto | null;

  bankDetailsScan: DownloadFileDto | null;

  wccScan: DownloadFileDto | null;

  promoScan: DownloadFileDto[];
  photoProof: DownloadFileDto[];

  status: PurchaseStatusDto;

  clientInvoiceRow: InvoiceRowDto;

  clientInvoice: InvoiceDto;

  supplierInvoiceRow: InvoiceRowDto;

  supplierInvoice: InvoiceDto;

  manager: UserDto;

  createdAt: Date;

  static fromPlain(obj: any) {
    return plainToInstance<PurchaseInfoDto, any>(PurchaseInfoDto, obj, { enableImplicitConversion: true });
  }
}

export class UpdatePurchaseAbstractDto {
  @IsNumber()
  @Type()
  id: number;

  @Type()
  @IsNumber()
  statusId: number;

  // unknown в Абстрактном классе, так как на сервере или клиенты разные типы: File (на клиенте) или MemoryStoredFile (на сервере)

  // File (на клиенте) или MemoryStoredFile (на сервере) - прикрепить новый файл (unknown в абстрактном DTO)
  // null - удалить прикрепленный файл
  // undefined - ничего не делать
  invoiceScan?: unknown;
  bankDetailsScan?: unknown;
  wccScan?: unknown;
  // Массив файлов - установить именно такой массив файлов
  // null - удалить все
  // undefined - ничего не делать
  promoScan?: unknown | unknown[];
  photoProof?: unknown | unknown[];
}

export class PurchaseDto extends PurchaseInfoDto {
}

export class CreatePurchaseDto {
  @IsNumber()
  @IsNotEmpty()
  clientInvoiceRowId: number;
}

export class UnlinkSupplierDto {
  @IsNumber()
  id: number;
}

export class LinkSupplierDto {
  @IsNumber()
  purchaseId: number;

  @IsNumber()
  invoiceRowId: number;
}

export class CreatePayOrderFromPurchaseDto {
  amount: number;
}

export class PurchaseStatusDto {
  id: number;
  label: string;
}

export class PurchaseStatusCheckDto {
  @IsIn(PURCHASE_STATUSES.map(x => x.id))
  status: number;
  ids: number[];
}
