import { extractNestJsErrorMessage } from '../../../shared/api/rtk-query';
import { parsedEmailApi } from '../api';


export const useParsedEmailDetails = (id: number) => {
  const { data, isLoading, isFetching, error } = parsedEmailApi.useGetParsedEmailQuery(
    id
  );

  return {
    parsedEmailData: data ? data : undefined,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading || isFetching,
  };
};

export const useGetMailAttachments = (ids: number[], skip?: boolean) => {
  const { data, isLoading, isFetching, error } = parsedEmailApi.useGetEmailAttachmentsQuery(ids, { skip: skip });
  return {
    attachments: data ? data : undefined,
    error: extractNestJsErrorMessage(error),
    isLoading: isLoading || isFetching,
  };
};
