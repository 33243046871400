import { Typography } from '@mui/material';
import { InvoiceDto } from '@sr/dto';
import { LegendItem, LegendList } from 'shared/ui/legend-list';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import PriceSpan from 'shared/ui/price-span';
import { InvoiceStatusSpan } from 'entities/invoice/status-span/status-span';
import { CardPaper } from 'shared/ui/card-paper';
import { formatDate } from 'utils/date-format-helpers';
import { useInvoiceTotals } from 'entities/invoice/totals';

export const InvoiceCard = ({ invoice }: { invoice: InvoiceDto }) => {
  const { totals } = useInvoiceTotals(invoice.id);
  return (
    <CardPaper>
      <Typography variant='h6'>Счет <LinkToInvoice invoice={invoice} /></Typography>
      <LegendList>
        {/* <LegendItem */}
        <LegendItem title="Статус: " value={<InvoiceStatusSpan status={invoice.status} />} />
        {totals && <LegendItem title="Сумма счета: " value={<PriceSpan size="small" price={totals.discountPriceTotal} />} />}
        <LegendItem title="Дата счета: " value={formatDate(invoice.startDate)} />
      </LegendList>
    </CardPaper>
  );
};
