import { InvoiceFilter, InvoiceInfoDto } from '@sr/dto';
import { fetchInvoicesDropdown } from '../api';
import { useCallback, useEffect, useState } from 'react';
import { useSnack } from 'shared/ui/snack';
import { debounce } from '@mui/material';

const DEBOUNCE_DELAY = 300;

export const useInvoicesDropdown = (filter: InvoiceFilter) => {
  const { invoiceNumber } = filter;
  const [items, setItems] = useState<InvoiceInfoDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showError } = useSnack();

  const fetchInvoices = async () => {
    setIsLoading(true);
    try {
      const response = await fetchInvoicesDropdown({
        invoiceNumber,
        ...filter,
      });
      setItems(response);
    } catch (error) {
      showError('Не удалось получить список счетов');
      setItems([]);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetchInvoices = useCallback(
    debounce(() => {
      fetchInvoices();
    }, DEBOUNCE_DELAY),
    [invoiceNumber]
  );

  useEffect(() => {
    debouncedFetchInvoices();
  }, [invoiceNumber]);

  useEffect(() => {
    return () => {
      debouncedFetchInvoices.clear();
    };
  }, [debouncedFetchInvoices]);

  return { items, isLoading };
};
