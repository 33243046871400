import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { ErrorBanner } from 'shared/ui/error-banner';
import { FormData, toDto } from 'components/Purchases/purchase.form';
import { useSnack } from 'shared/ui/snack';
import { PurchaseForm } from 'components/Purchases/purchase-form';
import { PurchaseSupplierInvoiceCard } from 'components/Purchases/purchase-supplier-card';
import { PurchaseClientInvoiceCard } from 'components/Purchases/purchase-client-card';
import { formatDate } from 'utils/date-format-helpers';
import { PurchaseComments } from 'components/Purchases/purchase-comments';
import DeleteIcon from '@mui/icons-material/Delete';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { canDeletePurchase } from '@sr/dto';
import { usePurchase } from 'entities/purchase/purchase.hook';
import { RelatedPurchases } from 'entities/invoice-report/related-purchases';
import { InvoiceRows } from 'entities/invoice/invoice-row/invoice-rows';
import { useState } from 'react';
import { PurchasePayOrderForm } from 'components/Purchases/purchase-payorder.form';
import { deletePurchase } from 'shared/api/purchases';

export const PurchaseDetailsPage = () => {
  const { id } = useParams<{ id: string; }>();
  const purchaseId = Number(id);
  const { purchase, updatePurchase, error, isLoading } = usePurchase(purchaseId);
  const [isEditMode, setIsEditMode] = useState(false);
  const navigate = useNavigate();
  const { showSuccess, showError } = useSnack();
  const showConfirmDialog = useConfirmDialog();

  const handleSubmit = async (formData: FormData) => {

    const dto = toDto(purchaseId, formData);

    updatePurchase(dto)
      .then(() => showSuccess('Данные закупки успешно обновлены'))
      .catch(e => showError(`Ошибка обновления данных закупки: ${e}`));
  };

  const handleDelete = async () => {
    showConfirmDialog({
      content: 'Удалить заявку?',
      rejectOnCancel: false
    })
      .then(() => {
        deletePurchase(purchaseId)
          .then(() => {
            showSuccess('Заявка успешно удалена');
            navigate(-1);
          })
          .catch(e => showError(`Ошибка удаления заявки: ${e}`));
      });
  };

  if (error)
    return <ErrorBanner errorMessage={error}/>;
  if (isLoading)
    return <LoadingBanner/>;
  if (!purchase)
    return <ErrorBanner errorMessage="Заявка не найдена"/>;

  const title = `Заявка № ${purchase.id} от ${formatDate(purchase.createdAt)}`;


  return (
    <>
      <Button startIcon={<ChevronLeftIcon />} onClick={() => navigate(-1)}>
        Назад
      </Button>

      <Box display={'flex'} alignItems={'center'} mt={1} mb={3} gap={2}>
        <Typography mb={0} gutterBottom variant='h5'>{title}</Typography>
        <Button
          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          size='large'
          disabled={!canDeletePurchase(purchase.status.id)}
          onClick={handleDelete}
          color='error'>
                    удалить
          <DeleteIcon/>
        </Button>
      </Box>

      <Stack direction='row' spacing={2} alignItems='stretch'>
        <Card sx={{ flex: 2 }}>
          <CardContent>
            <PurchaseForm
              purchase={purchase}
              onSubmit={handleSubmit}/>
          </CardContent>
        </Card>

        <Box sx={{ flex: 3 }}>
          <PurchaseClientInvoiceCard
            invoice={purchase.clientInvoice}
            invoiceRow={purchase.clientInvoiceRow}/>
        </Box>

        <Box sx={{ flex: 3 }}>
          <PurchaseSupplierInvoiceCard
            purchaseId={purchaseId}
            invoice={purchase.supplierInvoice}
            invoiceRow={purchase.supplierInvoiceRow}/>
        </Box>
      </Stack>

      {
        // отобразим строчки заявки детально.
        purchase.supplierInvoice &&
        <Card sx={{ my: 3 }}>
          <Stack direction='row' spacing={2} alignItems='center'>
            <CardHeader title="Услуги в заявке" />
            <FormGroup>
              <FormControlLabel
                control={<Switch onChange={(event)=>{ setIsEditMode(event.target.checked) }}/>}
                label="Режим редактирования" />
            </FormGroup>
          </Stack>
          <CardContent sx={{ py: 2 }}>
            {
              isEditMode
                ? <InvoiceRows
                  invoiceId={purchase.supplierInvoice.id}
                  invoiceRowId={purchase.supplierInvoiceRow.id}
                  purchaseId={purchaseId}
                  isClientInvoice={false}
                  showNewRow={false}
                  allowEdit={true}
                  onCancelNewRow={()=>{ window.alert('Canceling new row') }}
                  isCompact={true}
                />
                : <RelatedPurchases
                  purchases={{ items: [purchase], isLoading, error: error, reload: ()=>{ window.alert('Implement me') } }}
                  isCompact={true}
                />
            }
          </CardContent>
        </Card>
      }
      {
        purchase.supplierInvoice &&
        <PurchasePayOrderForm
          purchase={purchase}
          invoice={purchase.supplierInvoice}
          invoiceRow={purchase.supplierInvoiceRow}
        />
      }
      <Card sx={{ m: 3 }}>
        <CardContent>
          <PurchaseComments purchaseId={purchaseId}/>
        </CardContent>
      </Card>

    </>
  );
};

export default PurchaseDetailsPage;
