import { Box, Stack } from '@mui/material';
import { Field, FormikErrors } from 'formik';
import { TextField } from 'formik-mui';
import { SALARY_ENTRY_TYPES } from '@sr/dto';

import { useActionCreators, useStateSelector } from 'shared/store';
import { FormDialog } from 'shared/ui/form-dialog';
import { useSnack } from 'shared/ui/snack';
import { VALIDATION_REQUIRED_FIELD } from 'utils/form-tools';

import { salaryDialogActions } from './salary-entry.slice';
import { SalaryTypePicker } from './salary-entry-type.picker';
import { UserPickerField } from 'features/user/user-picker';
import { useCreateSalaryEntry } from './salary-entry-creation.hook';
import { SalaryPeriodField } from '../salary-period/salary-period.field';
import { Period } from 'entities/period-picker/types';

export type SalaryEntryCreationFormValues = {
  type: SALARY_ENTRY_TYPES | '';
  sum: number;
  userId: number | '';
  description: string;
  salaryPeriod: Period;
}

const validate = (values: SalaryEntryCreationFormValues): FormikErrors<SalaryEntryCreationFormValues> => {
  const errors: FormikErrors<SalaryEntryCreationFormValues> = {};
  if (values.type === '') {
    errors.type = VALIDATION_REQUIRED_FIELD;
  }
  if (values.description === '') {
    errors.description = VALIDATION_REQUIRED_FIELD;
  }
  if (values.sum <= 0) {
    errors.sum = 'Должна быть положительной';
  }
  if (!values.userId) {
    errors.userId = VALIDATION_REQUIRED_FIELD;
  }
  return errors;
};

export const SalaryEntryForm = () => {
  const { closeDialog } = useActionCreators(salaryDialogActions);
  const isDialogOpened = useStateSelector(state => state.ui.salaryDialog.isAddSalaryEntryDialogOpened);
  const userId = useStateSelector(state => state.salaryUserSelect.value);
  const selectedPeriod = useStateSelector((state) => state.salaryPageSelectedPeriod.value);
  const { createSalaryEntry } = useCreateSalaryEntry();
  const { showError, showSuccess } = useSnack();
  const initialValues: SalaryEntryCreationFormValues = {
    type: '',
    sum: 0,
    description: '',
    userId,
    salaryPeriod: selectedPeriod,
  };
  const handleSubmit = async (values: SalaryEntryCreationFormValues) => {
    await createSalaryEntry(values)
      .then(() => {
        showSuccess('Начисление/удержание успешно создано');
      })
      .catch((error) => {
        showError('Ошибка при внесении начисления/удержания: ', error);
      })
      .finally(() => {
        closeDialog();
      });
  };

  return (
    <FormDialog
      open={isDialogOpened}
      onClose={closeDialog}
      renderTitle="Внести начисление/удержание"
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      renderForm={(formikProps) => (
        <Box minWidth={400} pb={1}>
          <Stack spacing={2}>
            <Field
              required
              name="salaryPeriod"
              size="medium"
              component={SalaryPeriodField}
            />
            <Field
              fullWidth
              required
              name="userId"
              label="Сотрудник"
              component={UserPickerField}
            />
            <Field
              fullWidth
              required
              name="type"
              label="Тип внесения"
              component={SalaryTypePicker}
            />
            <Field
              fullWidth
              required
              name="sum"
              label="Сумма внесения"
              type="number"
              component={TextField}
            />
            <Field
              fullWidth
              required
              name="description"
              label="Основание внесения"
              component={TextField}
            />
          </Stack>
        </Box>
      )}
    />
  );
};
