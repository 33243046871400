import { FormControlLabel, MenuItem, Switch } from '@mui/material';
import { Interval, SupportedProviders } from '@sr/dto';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { UserPickerField } from 'features/user/user-picker';

export const EmailAccountForm = () => {

    return (
      <>
        <Field component={TextField} name="email" label="Email" placeholder="Email" type="email" required/>           
          <Field component={TextField} name="login" label="Логин" required/>
          <Field component={TextField} name="password" label="Пароль" type="password" required/>
          <Field
              fullWidth
              required
              name="userId"
              label="Сотрудник"
              component={UserPickerField}
            /> 
          <Field label='Провайдер' component={TextField} select name="provider" required>
            <MenuItem key={SupportedProviders.GOOGLE} value={SupportedProviders.GOOGLE} disabled>GOOGLE</MenuItem>
            <MenuItem key={SupportedProviders.YANDEX} value={SupportedProviders.YANDEX}>YANDEX</MenuItem>
          </Field>
          <Field label='Интервал опроса' component={TextField} select name="checkInterval" required>
          <MenuItem key={Interval.ONE_DAY} value={Interval.ONE_DAY}>День</MenuItem>
          <MenuItem key={Interval.ONE_HOUR} value={Interval.ONE_HOUR}>Час</MenuItem>
          <MenuItem key={Interval.ONE_MINUTE} value={Interval.ONE_MINUTE}>Минута</MenuItem>
          <MenuItem key={Interval.TEN_MINUTES} value={Interval.TEN_MINUTES}>10 минут</MenuItem>
          <MenuItem key={Interval.THIRTY_MINUTES} value={Interval.THIRTY_MINUTES}>30 минут</MenuItem>
          </Field>
          <Field
            name='active'
            control={<Switch />}
            type="checkbox"
            as={FormControlLabel}
            label='Активна'
          />
          <Field
            name='general'
            control={<Switch />}
            type="checkbox"
            as={FormControlLabel}
            label='Общий'
          />
      </>
    );
  };
  

export default EmailAccountForm;