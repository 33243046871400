import { TextFieldProps } from 'formik-mui';
import { SalaryPeriodPicker } from './salary-period-picker';
import { Period } from 'entities/period-picker/types';
import { InputSize } from 'shared/layout/constants';

type Props = {
  size: InputSize;
} & TextFieldProps;

export const SalaryPeriodField = (props: Props)=> {
  const {
    form: { setFieldValue },
    field: { name, value },
    size = 'small',
    ...textFieldProps
  } = props;

  const onSelect = async (period: Period) => {
    await setFieldValue(name, period);
  };

  return <SalaryPeriodPicker title={textFieldProps.label} selectedPeriod={value} onSelect={onSelect} size={size}/>;
};
