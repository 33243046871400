import { salaryEntryCreationApi } from './salary-entry-creation.api';
import { SalaryEntryCreationFormValues } from './salary-entry.form';

export const useCreateSalaryEntry = () => {
  const [create, {}] = salaryEntryCreationApi.useCreateSalaryEntryMutation();
  return {
    createSalaryEntry: async (formData: SalaryEntryCreationFormValues)=> {
      if (typeof formData.userId === 'string') throw new Error('UserId is required for useCreateSalaryEntry hook');
      const { userId, ...formValues } = formData;
      return create({ year: formData.salaryPeriod.year, month: formData.salaryPeriod.month, userId, formValues }).unwrap();
    }
  };
};
