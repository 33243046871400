import { LeadDto } from '@sr/dto';
import { Box } from '@mui/material';
import { CardPaperTitled } from 'shared/ui/card-paper';
import { ErrorBanner } from 'shared/ui/error-banner';

type EmailData = {
  from: string,
  to: string,
  subject: string,
}

const EmailPreview = ({ content }: { content: string }) => {
  try{
    const emailData: EmailData = JSON.parse(content);
    const html = `<b>Письмо от ${emailData.from} на ${emailData.to}</b><br/><p>Тема: ${emailData.subject}</p>`;
    return <Box dangerouslySetInnerHTML={{ __html: html }} />;
  } catch (e) {
    console.log(e);
    return <ErrorBanner errorMessage='Ошибка предпросмотра емейла' />;
  }
};

export const LeadContentWidget = ({ lead }: { lead: LeadDto }) => {
  return (
    <CardPaperTitled title="Содержание">
      {
        lead.emailId
          ? <EmailPreview content={lead.content}/>
          : <Box>{lead.content}</Box>
      }
    </CardPaperTitled>
  );
};
