import { MouseEvent, ReactNode, useEffect, useState } from 'react';
import MenuParams from 'shared/ui/menu-params';
import {
  TableRow,
  TableCell,
  IconButton,
  Chip,
  Paper,
  Stack,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TableFooter,
  Typography
} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { canDeletePurchase, InvoiceDto, InvoiceRowDto, IPagingData, PurchaseInfoDto } from '@sr/dto';
import { GridLayout, HeadCellsCollection } from 'shared/ui/grid-layout';
import { RowMenu, useMenu } from 'shared/ui/row-menu';
import { formatDate } from 'utils/date-format-helpers';
import { formatPerson } from 'utils/person-format-helpers';
import { LinkToPurchase } from 'shared/ui/links/link-to-purchase';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import { LinkToLegalEntity } from 'shared/ui/links/link-to-legal-entity';
import PriceSpan from 'shared/ui/price-span';
import { InfoBanner } from 'shared/ui/info-banner';
import { LinkToMedia } from 'shared/ui/links/link-to-media';
import { LegendItem } from 'shared/ui/legend-list';
import { IRemotePagedCollection } from 'utils/remote-paged-collection.hook';
import { PurchasesFilter } from 'features/purchase/purchases-filter';
import { PURCHASE_STATUSES } from '@sr/dto';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useSnack } from 'shared/ui/snack';
import { patchPurchaseStatus } from 'shared/api/purchases';

type Props = {
  collection: IRemotePagedCollection<PurchaseInfoDto>,
  paging: IPagingData,
  onChangePaging: (paging: IPagingData) => void,
  filterString?: string,
  onFilter?: (filterString: string) => void,
  onDelete: (purchase: PurchaseInfoDto) => void,
};

export const PurchasesList = ({ collection, paging, onChangePaging, filterString, onFilter, onDelete }: Props) => {
  const { openMenu, menuData } = useMenu<PurchaseInfoDto>({ deleteHandler: onDelete });

  const [checkedItems, setCheckedItems] = useState<number[]>([]);

  const handleToggleAll = (e: any, checked: boolean) => {
    if (checked)
      setCheckedItems(collection.collection.items.map(x => x.id));
    else
      setCheckedItems([]);
  };

  const isAllChecked = () =>
    checkedItems.length !== collection.collection.items.length;

  const handleCheckChange = (id: number, checked: boolean) => {
    setCheckedItems(!checked ? checkedItems.filter(x => x !== id) : [...checkedItems, id]);
  };

  const columns: HeadCellsCollection = [
    {
      label: <Checkbox
        onChange={handleToggleAll}
        value={!isAllChecked()}
        indeterminate={isAllChecked() && checkedItems.length > 0} />,
      align: 'left',
      padding: 'checkbox'
    },
    'Номер', 'Статус', 'Счёт', 'Счет подрядчика', 'Текст заявок',
    'Клиентская цена', 'Базовая цена', 'Сумма платежа',
    'Дата платежа',
    ''];

  const { showError, showSuccess } = useSnack();

  const [statusId, setStatus] = useState(0);
  const handleChange = (e: any) => {
    setStatus(e.target.value);
  };

  const [dirty, setDirty] = useState(false);
  useEffect(() => {
    if (statusId === 0) {
      setDirty(false);
    }
    else {
      setDirty(true);
    }
  }, [statusId]);



  const submitFormDirty = async (e: any) => {
    return patchPurchaseStatus({ status: Number(statusId), ids: checkedItems })
      .then(r => {
        if (Number(r) > 1) { showSuccess('Статус успешно изменен для ' + r + ' заявок') }
        else if (Number(r) == 0) { showError('Выберите хотя б одну заявку'); return false }
        else { showSuccess('Статус заявки успешно изменен') }
        //reload page
        window.location.reload();
      })
      .catch(e =>
        showError(`Ошибка изменения статуса: ${e}`)
      );
  };

  return (
    <>
      {filterString !== undefined && onFilter &&
        <Box my={1}>
          <PurchasesFilter filterString={filterString} onFilter={onFilter} />

        </Box>
      }
      <GridLayout
        columns={columns}
        items={collection.collection.items}
        isLoading={collection.isLoading}
        error={collection.error}
        noItemsText='Список заявок пуст'
        pagingData={paging}
        onChangePaging={onChangePaging}
        totalItemsCount={collection.collection.totalItemsCount}

        itemRender={(i) => <PurchaseRow
          key={i.id}
          purchase={i}
          onMenuClick={openMenu}

          checked={checkedItems.some(x => x === i.id)}
          onCheckChange={(checked) => handleCheckChange(i.id, checked)}
        />}
        footer={SubtotalRow}
      />
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell colSpan={3} >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Статус</InputLabel>
            <Select
              style={{ width: '150px' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={statusId}
              label="Статус"
              onChange={handleChange}
            >
              <MenuItem key="" value={0}>&nbsp;</MenuItem>
              {PURCHASE_STATUSES.map((s) => (
                <MenuItem key={s.id} value={s.id} > {s.label} </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          <Button
            onClick={submitFormDirty}
            variant="contained"
            disabled={!dirty}
            endIcon={<ModeEditIcon />}
          >
            Изменить
          </Button>
        </TableCell>
      </TableRow>
      <RowMenu menuData={menuData} />
    </>);
};


type RowProps = {
  purchase: PurchaseInfoDto,
  onMenuClick: (menuParams: MenuParams<PurchaseInfoDto>) => void;
  checked: boolean;
  onCheckChange: (checked: boolean) => void;
};

export const PurchaseRow = (props: RowProps) => {
  const { purchase, onMenuClick, checked, onCheckChange } = props;
  const status = purchase.status.label;

  const manager = purchase.manager ? formatPerson(purchase.manager) : '-';
  const paymentDate =  '-';
  const paymentAmount =  '-';


  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    onMenuClick({ anchor: e.currentTarget, target: purchase });
  };

  return (
    <>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        {/* <TableCell>
          <input type="checkbox" id={`${purchase.id}`} className='purchases'/>
        </TableCell> */}
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={checked}
            onChange={(e, checked) => onCheckChange(checked)}
          />
        </TableCell>
        <TableCell>
          <LinkToPurchase purchase={purchase} />
        </TableCell>
        <TableCell>
          <Chip label={status} variant="outlined" />
        </TableCell>
        <TableCell>
          <ClientCard invoice={purchase.clientInvoice} invoiceRow={purchase.clientInvoiceRow} />
        </TableCell>
        <TableCell>
          {purchase.supplierInvoice
            ? <ClientCard invoice={purchase.supplierInvoice} invoiceRow={purchase.clientInvoiceRow} />
            : <InfoBanner message='Счет подрядчика не указан' />}</TableCell>
        <TableCell>
          <LegendCard>
            <LegendItem title="Продажа:" value={
              <>
                {purchase.clientInvoiceRow.description}
                {purchase.clientInvoiceRow.media && (
                  <LinkToMedia media={purchase.clientInvoiceRow.media}>
                    &nbsp;({purchase.clientInvoiceRow.media.name})
                  </LinkToMedia>)
                }
              </>}
            />
            <LegendItem title="Закупка:" value={
              <>
                {purchase.supplierInvoiceRow ?
                  purchase.supplierInvoiceRow.description
                  : '-'}
              </>
            } />
          </LegendCard>
        </TableCell>
        <TableCell><PriceSpan price={purchase.clientInvoiceRow.priceDiscount} /></TableCell>
        <TableCell>{manager}</TableCell>
        <TableCell>{paymentAmount}</TableCell>
        <TableCell>{paymentDate}</TableCell>
        <TableCell align="right">
          {canDeletePurchase(purchase.status.id) ? <IconButton edge="end" onClick={handleMenuClick}><MoreVertIcon /></IconButton> : null}
        </TableCell>
      </TableRow>
    </>
  );
};

const SubtotalRow = (rows: PurchaseInfoDto[]) => {
  const sum = rows.reduce((total, row) => total + row.clientInvoiceRow.priceDiscount, 0);

  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={6} align='right'><Typography variant='subtitle1'>Итого:</Typography> </TableCell>
        <TableCell align='left'><PriceSpan price={sum} size="small" /></TableCell>
      </TableRow>
    </TableFooter>
  );
};


const ClientCard = ({ invoice, invoiceRow }: { invoice: InvoiceDto, invoiceRow: InvoiceRowDto; }) => {
  return (
    <Paper sx={{ p: 1 }}>
      <Stack direction='column' spacing={0}>
        <LegendItem title="Счет:" value={<LinkToInvoice invoice={invoice} />} />
        <LegendItem title="Сумма:" value={<PriceSpan size='small' price={invoice.totals.discountPriceTotal} />} />
        <LegendItem title="Наше юрлицо:" value={<LinkToLegalEntity legalEntity={invoice.seller} />} />
      </Stack>
    </Paper>
  );
};

const LegendCard = ({ children }: { children: ReactNode; }) => {
  return (
    <Paper sx={{ p: 1 }} >
      <Stack direction='column' spacing={0}>
        {children}
      </Stack>
    </Paper>);
};


