import { TableCell, TableRow } from '@mui/material';
import { InvoiceDto } from '@sr/dto';
import { GridLayout } from 'shared/ui/grid-layout';
import { LinkToInvoice } from 'entities/client-invoices-list/ui/link-to-invoice';
import PriceSpan from 'shared/ui/price-span';
import { formatDate } from 'utils/date-format-helpers';
import { formatPerson } from 'utils/person-format-helpers';
import { InvoiceStatusSpan } from '../invoice/status-span/status-span';
import { useRelatedPurchases } from './related-purchases.hook';

type Props = {
  invoiceId: number;
};

export const RelatedInvoices = ({ invoiceId }: Props) => {

  const { relatedInvoices } = useRelatedPurchases(invoiceId);
  return (
    <>
      <GridLayout<InvoiceDto>
        columns={['Дата', 'Номер', 'Менеджер', 'Сумма', 'Статус']}
        items={relatedInvoices.items}
        isLoading={relatedInvoices.isLoading}
        error={relatedInvoices.error}
        itemRender={invoice => (<Row key={invoice.id} invoice={invoice} />)}
        noItemsText='Нет связных счетов'
        footer={(items) => (
          <TableRow>
            <TableCell colSpan={3} align='right'>Итого:</TableCell>
            <TableCell>
              <PriceSpan size='small' price={items.reduce((prev, curr) => prev + curr.totals.discountPriceTotal, 0)} />
            </TableCell>
          </TableRow>)}
      />
    </>
  );
};

const Row = ({ invoice }: { invoice: InvoiceDto; }) => {
  return (
    <TableRow>
      <TableCell>
        <LinkToInvoice invoice={invoice}>
          {formatDate(invoice.startDate)}
        </LinkToInvoice>
      </TableCell>
      <TableCell>
        <LinkToInvoice invoice={invoice}>
          {invoice.invoiceNumber}
        </LinkToInvoice>
      </TableCell>
      <TableCell>
        {formatPerson(invoice.manager)}
      </TableCell>

      <TableCell>
        {invoice.totals.partialPaidAmount !== 0 &&
          <>
            <PriceSpan size='small' price={invoice.totals.partialPaidAmount} />/
          </>
        }
        <PriceSpan size='small' price={invoice.totals.discountPriceTotal} />
      </TableCell>

      <TableCell>
        <InvoiceStatusSpan status={invoice.status} size='small' />
        {/* <Box sx={{ width: 100 }}>
          {invoice.totals.partialPaidAmount && <PaidProgress variant="determinate" value={100 * (invoice.totals.partialPaidAmount / invoice.totals.discountPriceTotal)} />}

        </Box> */}
      </TableCell>

    </TableRow>);
};
