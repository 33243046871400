import { Typography } from '@mui/material';
import { useSalaryPeriods } from './salary-period.hook';
import { ErrorBanner } from 'shared/ui/error-banner';
import { PeriodPicker } from 'entities/period-picker/period-picker';
import { Period } from 'entities/period-picker/types';
import { getCurrentPeriod } from 'entities/period-picker/period.helpers';
import { ReactNode } from 'react';
import { InputSize } from 'shared/layout/constants';

interface Props {
  title?: ReactNode;
  selectedPeriod?: Period;
  onSelect: (period: Period) => void;
  size?: InputSize;
  readOnly?: boolean;
}

export const SalaryPeriodPicker = (
  { title = 'Период закрытия', size = 'small', selectedPeriod = getCurrentPeriod(), onSelect, readOnly = false }: Props
) => {
  const { salaryPeriods, isLoading, error } = useSalaryPeriods();
  if (error) {
    return <ErrorBanner errorMessage={error}/>;
  }

  return (
    <>
      {title && <Typography variant='subtitle1' sx={{ my:1 }}>{title}</Typography>}
      <PeriodPicker size={size} periods={salaryPeriods} onSelect={onSelect} selectedPeriod={selectedPeriod} isLoading={isLoading} readOnly={readOnly}/>
    </>
  );
};
