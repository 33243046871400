import 'reflect-metadata';
import { InvoiceFilter, } from '@sr/dto';
import { InvoiceCanceledStatusDto,  InvoiceInfoDto, IPagingData } from '@sr/dto';
import { authorizedRequest, fetchCollectionReq, fetchPagedReq } from 'shared/api/axios';
import { INVOICES_BASE_URL, PRINT_FORMS_BASE_URL } from 'shared/api/api';
import {
  DEFAULT_INVOICE_STATUSES,
  INVOICE_STATUS_QUERY_KEY,
} from './invoices.constants';

export const fetchClientInvoicesDropdown = async (companyId?: number) => {
  const response = await fetchCollectionReq<InvoiceInfoDto>(
    `${INVOICES_BASE_URL}/client` + (companyId ? `?company=${companyId}` : ''),
    InvoiceInfoDto
  );
  return response;
};

export const fetchClientInvoices = async (
  paging: IPagingData,
  filterString?: string,
  companyId?: number
) => {
  const params = new URLSearchParams(filterString);

  if (!params.get(INVOICE_STATUS_QUERY_KEY)) {
    params.set(INVOICE_STATUS_QUERY_KEY, DEFAULT_INVOICE_STATUSES.join(','));
  }

  let url = `${INVOICES_BASE_URL}/client`;
  if (companyId) {
    url += `/${companyId}`;
  }

  const response = await fetchPagedReq<void, InvoiceInfoDto>(
    `${url}?${params.toString()}`,
    paging
  );
  return response;
};

export const fetchSupplierInvoices = async (
  paging: IPagingData,
  filterString?: string,
  companyId?: number
) => {
  const params = new URLSearchParams(filterString);

  if (!params.get(INVOICE_STATUS_QUERY_KEY)) {
    params.set(INVOICE_STATUS_QUERY_KEY, DEFAULT_INVOICE_STATUSES.join(','));
  }

  let url = `${INVOICES_BASE_URL}/supplier`;
  if (companyId) {
    url += `/${companyId}`;
  }

  const response = await fetchPagedReq(
    `${url}?${params.toString()}`,
    paging,
    InvoiceInfoDto
  );
  return response;
};

export const fetchInvoicesDropdown = async (filter: InvoiceFilter) => {
  const params = new URLSearchParams();

  if (filter.invoiceNumber)
    params.append('invoiceNumber', filter.invoiceNumber.toString());
  if (filter.referenceInvoiceId)
    params.append('referenceInvoiceId', filter.referenceInvoiceId.toString());
  if (filter.buyerLegalEntityId)
    params.append('buyerLegalEntityId', filter.buyerLegalEntityId.toString());
  if (filter.sellerLegalEntityId)
    params.append('sellerLegalEntityId', filter.sellerLegalEntityId.toString());
  if (filter.type) params.append('type', filter.type.toString());
  if (filter.statusIds) params.append('statusIds', filter.statusIds.join(','));

  const response = await fetchCollectionReq<InvoiceInfoDto>(
    `${INVOICES_BASE_URL}/dropdown?${params.toString()}`,
    InvoiceInfoDto
  );
  return response;
};

export const changeInvoiceCanceledStatus = async (
  id: number
): Promise<InvoiceCanceledStatusDto> => {
  const response = await authorizedRequest<void, InvoiceCanceledStatusDto>(
    `${INVOICES_BASE_URL}/${id}/canceled-status`,
    'PUT'
  );
  return response;
};

export const fetchInvoicePrintForm = async (
  invoiceId: number,
  withStamp = false
) => {
  const response = await authorizedRequest<void, string>(
    `${PRINT_FORMS_BASE_URL}/invoice/${invoiceId}?stamp=${
      withStamp ? 'true' : ''
    }`,
    'GET'
  );
  return response;
};
