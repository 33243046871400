import { ReactNode } from 'react';
import { Stack, Box, Typography } from '@mui/material';

export const LegendList = ({ spacing = 1, children }: { spacing?: number, children: ReactNode }) => {
  return (
    <Stack direction='column' spacing={spacing} >
      {children}
    </Stack>);
};

export const LegendItem = ({ title, value }: { title: ReactNode, value: ReactNode | string }) => {
  return (
    <Box>
      <Stack direction='row' alignItems="flex-start">
        <Typography variant='subtitle1' color='text.secondary' minWidth={150} marginRight={1}>
          {title}
        </Typography>
        <Box>
          {value}
        </Box>
      </Stack>
    </Box >
  );
};
