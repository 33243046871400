import { useState } from 'react';
import { Box, Button, IconButton, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { InvoicePicker } from 'shared/ui/formik-fields/invoice-picker';
import useConfirmDialog from 'hooks/confirm-dialog.hook';
import { Field, FieldArray, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { FormData, RowData } from './pay-order.form-tools';
import { INVOICE_STATUS_DEBT, INVOICE_STATUS_NEW, INVOICE_STATUS_PARTIAL_PAID, INVOICE_STATUS_WCC_CREATED } from '@sr/dto';

export const PayOrderInvoiceRows = ({ invoiceType }: {invoiceType?: number | null}) => {
  const { values, isSubmitting,  } = useFormikContext<FormData>();
  const [idCounter, setIdCounter] = useState(-values.rows.length-1);
  const showConfirm = useConfirmDialog();
  const primaryInvoice = values.rows[0]?.invoiceId;

  const getNewRow = () => {
    const newRow: RowData = {
      id: idCounter,
      invoiceId: '',
      amount: ''
    };
    setIdCounter((prev) => prev - 1);
    return newRow;
  };


  return (
    <FieldArray name="rows">
      {(arrayHelper) => (
        <Stack direction="column" spacing={2}>
          {values.rows.map((row, index) => (
            <Box key={row.id}>
              <Stack direction='row' spacing={1} alignItems="flex-end">
                <Field
                  name={`rows.${index}.invoiceId`}
                  statusIds={[INVOICE_STATUS_NEW, INVOICE_STATUS_PARTIAL_PAID, INVOICE_STATUS_DEBT, INVOICE_STATUS_WCC_CREATED]}
                  invoiceType={invoiceType}
                  referenceInvoiceId={primaryInvoice}
                  component={InvoicePicker}
                />
                <Field
                  name={`rows.${index}.amount`}
                  component={TextField}
                />

                <IconButton
                  component="label"
                  color="primary"
                  disabled={index === 0}
                  onClick={() => showConfirm({ content: 'Удалить строчку?' }).then(() => arrayHelper.remove(index))}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </Box>
          ))}


          <Box display='flex' justifyContent='flex-end'>
            <Button
              variant='text'
              size='small'
              onClick={() => { arrayHelper.push(getNewRow()) }}
              disabled={isSubmitting}
            >
              Добавить сумму...
            </Button>
          </Box>
        </Stack >
      )}
    </FieldArray>
  );
};

