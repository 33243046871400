import { UpdateWccDto, WccDto } from '@sr/dto';
import { useState, useEffect } from 'react';
import { fetchInvoiceWcc, postInvoiceWcc, deleteInvoiceWcc, fetchUpdateWcc, fetchWcc } from './api';

type Props = {
  invoiceId?: number;
  wccId?: number;
  initialWcc: WccDto | null
}
/**
 * TODO: this should be rewritten to RTK
 * @param invoiceId  номер счета
 * @param initialWcc начальное значение актов (например из счета)
 */
export const useInvoiceWcc = ({ invoiceId, wccId, initialWcc }: Props) => {
  const [wcc, setWcc] = useState<WccDto | null>(initialWcc);

  const [isFetchingWcc, setFetchingWcc] = useState(false);
  const [isPostingWcc, setPostingWcc] = useState(false);

  useEffect(() => {
    if (initialWcc) {
      setWcc(initialWcc);
      return;
    }
    setFetchingWcc(false);
    if (invoiceId) {
      fetchInvoiceWcc(invoiceId)
        .then(invoiceWcc => {
          setWcc(invoiceWcc.wcc);
        });
    }
    if (wccId) {
      fetchWcc(wccId)
        .then(wcc => {
          setWcc(wcc);
        });
    }
    setFetchingWcc(false);
  }, [invoiceId, initialWcc]);

  const createWcc = async () => {
    if (!invoiceId) throw new Error('Invoice ID is required');
    setPostingWcc(true);
    return postInvoiceWcc({ invoiceId })
      .then(result => {
        setWcc(result.wcc);
      })
      .finally(() => setPostingWcc(false));
  };

  const deleteWcc = async () => {
    if (!invoiceId) throw new Error('Invoice ID is required');
    setPostingWcc(true);
    return deleteInvoiceWcc(invoiceId)
      .then(() => {
        setWcc(null);
      })
      .finally(() => setPostingWcc(false));
  };

  const updateWcc = async (dto: UpdateWccDto) => {
    setPostingWcc(true);
    return fetchUpdateWcc(dto)
      .then((res) => {
        setWcc({ ...res });
      })
      .finally(() => setPostingWcc(false));

  };

  return ({
    invoiceWcc: wcc,
    createWcc,
    updateWcc,
    deleteWcc,
    isFetchingWcc,
    isPostingWcc
  });
};

