import { LegalEntityTypeDto, LegalEntityTypeBaseInfoDto, EmailAccountLogParserDto, EmailAccountDto } from '@sr/dto';
import { Link } from 'shared/ui/link-base';
import { legalEntityTypeManager } from 'utils/link-manager';
import { ReactNode } from 'react';

export const emailAccountLink = {
  baseUrl: 'email-parser',
  link: function (emailAccountId: number) {
    return `/${this.baseUrl}/${emailAccountId}`;
  },
};

type Props = {
  children?: ReactNode,
  emailAccount: EmailAccountLogParserDto | EmailAccountDto
}

export const LinkToEmailAccount = ({ emailAccount, children }: Props) => {
  const link = emailAccountLink.link(emailAccount.id);
  return (
    <Link to={link} >
      {emailAccount.email}
    </Link>
  );
};
