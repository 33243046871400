import { Button, Card, CardActions, CardContent, MenuItem, Stack } from '@mui/material';
import { UpdateWccDto, WccDto, WCC_STATUSES_MAP, WccStatus } from '@sr/dto';
import { useSnack } from 'shared/ui/snack';
import { FormValues, formTools } from './wcc-basic.form-tools';
import { Field, Formik, FormikProps } from 'formik';
import { DatePickerField } from 'shared/ui/formik-fields/date-picker';
import { TextField, Select } from 'formik-mui';

export const WccBasicsForm = ({ wcc, updateWcc }: { wcc: WccDto, updateWcc: (dto: UpdateWccDto) => Promise<void>; }) => {
  const { showSuccess, showError } = useSnack();

  const handleSubmit = async (formData: FormValues) => {
    return updateWcc(formTools.toUpdateDto(wcc.id, formData))
      .then(() => showSuccess('Данные акта успешно обновлены'))
      .catch(e => showError(`Ошибка обновления данных акта: ${e}`));
  };

  return (
    <Card>
      <Formik
        initialValues={formTools.fromDto(wcc)}
        enableReinitialize
        onSubmit={handleSubmit}
        validate={formTools.validate}
      >
        {({ submitForm, isSubmitting, dirty }: FormikProps<FormValues>) => (
          <>
            <CardContent>
              <Stack direction="column" spacing={2}>
                <Field
                  name="date"
                  label="Дата акта"
                  component={DatePickerField}
                />

                <Field
                  name="wccCost"
                  component={TextField}
                  label="Сумма акта"
                />

                <Field
                  name="status"
                  label="Статус"
                  component={Select}
                >
                  {Object.values(WccStatus).map(key =>
                    <MenuItem key={key} value={key}>
                      {WCC_STATUSES_MAP[key]}
                    </MenuItem>
                  )}
                </Field>
              </Stack>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                sx={{ ml: 'auto', mr: 1 }}
                onClick={submitForm}
                disabled={!dirty || isSubmitting}
              >
                Сохранить
              </Button>
            </CardActions>
          </>
        )}
      </Formik>
    </Card >
  );
};
