import { APP_BASE_URL } from '../../shared/api/api';
import { setAuthHeader } from '../../shared/api/rtk-query';
import { DownloadFileDto, ParsedEmailsDto } from '@sr/dto';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const parsedEmailApi = createApi({
  reducerPath: 'parsedEmailApi',
  baseQuery: fetchBaseQuery({
    baseUrl: APP_BASE_URL,
    prepareHeaders: (headers, api) => {
      setAuthHeader(headers);
      return headers;
    },
  }),
  tagTypes: ['*'],
  endpoints: (builder) => {
    return ({
      getParsedEmail: builder.query<ParsedEmailsDto, number>({
        query: (id: number) => ({
          url: `email-parser/${id}`,
          method: 'GET',
        }),
        providesTags: ['*'],
      }),
      getEmailAttachments: builder.query<DownloadFileDto[], number[]>({
        query: (ids: number[]) => ({
          url: `files?ids=${ids}`,
          method: 'GET',
        }),
        providesTags: ['*'],
      })
    });
  }
});
