import { memo, useState } from 'react';
import {
  Stack,
  Typography,
  Card,
  Divider,
  Box,
  Container,
  Button
} from '@mui/material';
import CallMadeIcon from '@mui/icons-material/CallMade';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Phone from '@mui/icons-material/Phone';
import { ToggleButton } from '../../Mui/toggle-button';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonSpan from 'shared/ui/person-span';
import ManagerSpan from 'shared/ui/manager-span';
import { formatDate } from 'utils/date-format-helpers';
import { LinkToCompany } from 'shared/ui/links/link-to-company';
import { EventDto, EventTaskType } from '@sr/dto';
import { useUpdateEventToggle } from 'features/events/events-actions/event-actions.hook';
import { useSnack } from 'shared/ui/snack';
import { extractNestJsErrorMessage } from 'shared/api/rtk-query';
import MailIcon from '@mui/icons-material/Mail';
import { ViewEmailModal } from './view-email-modal';
import AudioPlayer from 'entities/audio-player/audio-player';
import { ErrorBanner } from '../../../shared/ui/error-banner';


type Props = {
  item: EventDto,
  showCompany?: boolean;
};

type EmailData = {
  from: string,
  to: string,
  subject: string,
  textPreview: string,
}

const EmailPreview = ({ content }: { content: string }) => {
  try{
    const emailData: EmailData = JSON.parse(content);
    const html = `<b>Письмо от ${emailData.from} на ${emailData.to}</b><br/><br/><b>Тема: ${emailData.subject}</b><br/>${emailData.textPreview}`;
    return <Box dangerouslySetInnerHTML={{ __html: html }} />;
  } catch (e) {
    console.log(e);
    return <ErrorBanner errorMessage='Ошибка предпросмотра емейла' />;
  }
};

const EventItem = (props: Props) => {
  const {
    item: {
      id,
      taskType,
      content,
      manager,
      completed,
      important,
      contactPerson,
      createdDate: createdDateStr,
      dueDate: dueDateStr,
      company,
      emailId,
      atsAudioLink
    },
    showCompany = false,
  } = props;
  const dueDate = dueDateStr ? new Date(dueDateStr) : null;
  const createdDate = new Date(createdDateStr);
  const { showError } = useSnack();
  const [openEmailModal, setOpenEmailModal] = useState<boolean>(false);

  const updateEventToggle = useUpdateEventToggle();

  const handleImportantClick = async () => {
    await updateEventToggle({ id, completed, important: !important }).catch((e)=>{
      showError('Не получилось: ' + extractNestJsErrorMessage(e));
    });
  };

  const handleDoneClick = async () => {
    await updateEventToggle({ id, completed: !completed, important }).catch((e)=>{
      showError('Не получилось: ' + extractNestJsErrorMessage(e));
    });
  };
  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        {showCompany &&
          <Box sx={{
            background: (theme) => theme.palette.grey[300],
            p: 1 }}>
            Фирма: <LinkToCompany company={company}/>
          </Box>}
        <Stack direction="row" alignItems="center" spacing={2} sx={{ p:1 }}>
          <Box
            sx={{
              width: 48,
              height: 48,
              flexShrink: 0,
              display: 'flex',
              borderRadius: 1.5,
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'background.neutral',
            }}
          >
            {getIcon(taskType)}
          </Box>

          <Box sx={{ flexGrow: 1, minWidth: 160 }}>
            <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 0, color: 'text.secondary' }}>
              {manager && <ManagerSpan person={manager} />}
              {contactPerson && <ArrowForwardIosIcon fontSize='small' />}
              {contactPerson && <PersonSpan person={contactPerson} />}
            </Stack>
            <Box sx={{ mt: 1, mb: 1 }} >
              {dueDate &&
                  <Stack direction='row' alignItems='baseline' spacing={1}>
                    <Typography variant="subtitle2" sx={{ mt: 0.5, color: 'text.secondary' }}>
                      Назначенная дата:
                    </Typography>
                    <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
                      {formatDate(dueDate)}
                    </Typography>
                  </Stack>
              }
            </Box>
          </Box>


          <Stack alignItems="flex-end" sx={{ pr: 1 }}>
            <Stack direction='row' spacing={1}>
              <ToggleButton
                active={completed!}
                activeCaption='выполнено'
                inactiveCaption='выполнить'
                color='primary'
                size = "small"
                onClick={handleDoneClick} />
              <ToggleButton
                active={!important}
                activeCaption='важное'
                inactiveCaption='важное'
                color='warning'
                size = "small"
                onClick={handleImportantClick} />
              {emailId && <Button
                variant="outlined"
                onClick={()=> setOpenEmailModal(true)}
                endIcon={<MailIcon />}>
                Email
              </Button>
              }
            </Stack>
            <Typography variant="caption" sx={{ mt: 0.5, color: 'text.secondary' }}>
              {formatDate(createdDate)}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        {atsAudioLink !== undefined && <AudioPlayer audioUrl={atsAudioLink}/>}
        <Container sx={{ p:2 }}>
          {emailId
            ? <EmailPreview content={content}/>
            :<Box dangerouslySetInnerHTML={{ __html: content }} />}
        </Container>
      </Card >
      {emailId && openEmailModal &&  <ViewEmailModal onClose={() => setOpenEmailModal(false)} open={openEmailModal} id = {emailId}/>}
    </>

  );
};

export default memo(EventItem, (prevProps, nextProps)=>
  prevProps.item.id === nextProps.item.id &&
  prevProps.item.important === nextProps.item.important &&
  prevProps.item.completed === nextProps.item.completed
);

function getIcon(type: EventTaskType|string) {
  switch (type) {
  case 'call_in':
    return (<>
      <Phone color='primary' />
      <CallReceivedIcon color='primary' />
    </>);
  case 'call_out':
    return (<>
      <PhoneEnabledIcon color='primary' />
      <CallMadeIcon color='primary' />
    </>);
  case 'email_in':
    return (<>
      <MailOutlineIcon color='primary' />
      <CallReceivedIcon color='primary' />
    </>);
  case 'email_out':
    return (<>
      <MailOutlineIcon color='primary' />
      <CallMadeIcon color='primary' />
    </>);
  case 'meeting':
    return <Diversity3Icon color='primary' /> ;
  }
}
