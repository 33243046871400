import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem, Stack
} from '@mui/material';
import { getMonthNameFromMonthIdx } from './period.helpers';
import { Period } from './types';
import { LoadingBanner } from 'shared/ui/loading-banner';
import { ErrorBanner } from 'shared/ui/error-banner';
import { InputSize } from 'shared/layout/constants';

type Props = {
  selectedPeriod: Period,
  onSelect: (value: Period) => void
  periods?: Period[],
  isLoading?: boolean,
  size?: InputSize,
  readOnly? : boolean,
}

export function PeriodPicker({ onSelect, selectedPeriod, periods, isLoading = false, size = 'small', readOnly = false }: Props) {
  if (isLoading)
    return <Box sx={{ width:300, height: 40 }}><LoadingBanner /></Box>;

  if (!periods)
    return <ErrorBanner errorMessage={'Нет доступных периодов'}/>;

  const years = Array.from(new Set(periods.map(period=>period.year)));
  const monthIdxs = Array.from(new Set(periods.filter(period=>period.year===selectedPeriod.year).map(period=>period.month)));
  const handleMonthChange = (event: SelectChangeEvent<number>) =>{
    if (typeof event?.target?.value !== 'number') return;
    const selectedMonthIdx = event.target.value;
    const newPeriod = periods
      ?.find(period=> period.year === selectedPeriod.year && period.month === selectedMonthIdx);
    if (!newPeriod) throw new Error('Selected period was not found in available periods');
    onSelect(newPeriod);
  };

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    if (typeof event?.target?.value !== 'number') return;
    const newYear = event.target.value;
    const newPeriod = periods
      ?.filter(period=>period.year === newYear)
      .sort((a,b)=> a.month - b.month)[0]; //first available month from selected year

    if (!newPeriod) throw new Error('Selected period was not found in available periods');
    onSelect(newPeriod);
  };

  return (
    <Stack direction='row' spacing={1}>
      <FormControl size="small" margin='normal' sx={{ minWidth: 100 }}>
        <InputLabel id="year-picker">Год</InputLabel>
        <Select
          disabled={readOnly}
          labelId="year-picker"
          value={selectedPeriod.year}
          label="Год"
          onChange={handleYearChange}
          size={size}
          sx={{ backgroundColor: 'white' }}
        >
          {years.map((year)=>(
            <MenuItem value={year} key={year}>
              {String(year)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth={true} size="small" margin='normal' sx={{ minWidth: 150 }}>
        <InputLabel id="month-picker">Месяц</InputLabel>
        <Select
          disabled={readOnly}
          labelId="month-picker"
          value={selectedPeriod.month}
          label="Месяц"
          onChange={handleMonthChange}
          size={size}
          sx={{ backgroundColor: 'white' }}
        >
          {monthIdxs.map((monthIdx)=>(
            <MenuItem value={monthIdx} key={monthIdx}>
              {getMonthNameFromMonthIdx(monthIdx)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
